import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";
import Modal from "./modules/frontv2/Modal";
import SearchBar from "./modules/frontv2/SearchBar";
import MobileMenu from "./modules/frontv2/MobileMenu";
import MobileSearch from "./modules/frontv2/MobileSearch";
import Wish from "./modules/frontv2/Wish";
import * as Sentry from "@sentry/browser";
import { getUserLanguage } from "./modules/utils";
import { initDrops } from "./modules/common/drop";
import { initScroll } from "./modules/scrolling";
import { initAutocomplete } from "./modules/frontv2/Autocomplete";
import { initMobileMenu } from "./modules/common/mobile-menu";
import { initSelects } from "./modules/common/selects";
import { initFormValidation } from "./modules/common/form-validation";
import { initGoogleEcommerce } from "./modules/google_ecommerce";
import { initGlide } from "./modules/common/glide";
import { initCollapsible } from "./modules/collapsible";
import { initCommon } from "./modules/common/common";
import { initScrollSearchBar } from "./modules/frontv2/scrollSearchBar";
import { initSeeMoreContent } from "./modules/frontv2/seeMoreContent";
import MapHostSearch from "./modules/frontv2/MapHostSearch";
import { initScrollToTop } from "./modules/frontv2/ScrollToTop";
import { initSwiper } from "./modules/common/init-swiper";
import { initHideWorryFreeBlock } from "./modules/frontv2/Location/hideWorryFreeBlock";
import { initScrollInto } from "./modules/common/scroll-into";
import { initHomeSeoBlock } from "./modules/frontv2/init-home-seo-block";
import LoadHostExperiences from "./modules/frontv2/LoadHostExperiences";

document.addEventListener('DOMContentLoaded', () => {
  // Init Sentry JS
  if(document.body.dataset.env === 'prod') {
    Sentry.init({ dsn: 'https://b14c92e307604f4f8f454ebdd3eab1ef@sentry.io/1441102' });
  }

  flatpickr.localize(flatpickrLanguages[getUserLanguage()]);

  //common stuff
  initCommon();

  //init glide (carousel)
  initGlide();

  //Dropdowns and dropups
  initDrops();

  //Modal popups
  Modal.bindAllModals();

  //Init questions (faq) logic
  initCollapsible();

  //Header bar scroll logic
  initScroll();

  //Search bar logic (algolia)
  initAutocomplete();

  //Mobile menu
  initMobileMenu();

  //Init selects for langs (down the page)
  initSelects();

  //Init search logic
  new SearchBar('search-bar');

  //Mobile logic
  new MobileMenu();
  new MobileSearch();

  //Init wish bind buttons
  Wish.bindWishButtons();

  //Init form validation + recaptcha
  initFormValidation();

  //Analytics
  initGoogleEcommerce();

  //init scroll search bar
  initScrollSearchBar();

  initSeeMoreContent();

  //init search map with hosts
  const searchMapHostsWrapper = document.getElementById('search-map-hosts');
  const searchWrapper = document.getElementById('search-wrapper');
  if(searchMapHostsWrapper && !searchMapHostsWrapper.hasAttribute('data-is-bot') && searchWrapper){
    if(typeof maplibregl === 'undefined'){
      //on mobile, load the map when a click occurs on map (cause map load is heavy)
      if(searchMapHostsWrapper.hasAttribute('data-is-mobile') && !searchWrapper.hasAttribute('data-trigger-search-on-page-load')){
        let mapLoaded = false;

        //load the map anyway at some point, to prevent INP (core vital)
        window.setTimeout(() => {
          if(!mapLoaded){
            import('maplibre-gl').then(maplibregl => {
              new MapHostSearch(searchMapHostsWrapper, maplibregl);
              mapLoaded = true;
            });
          }
        }, 3000);

        window.addEventListener('click', (e) => {
          if(!mapLoaded){
            import('maplibre-gl').then(maplibregl => {
              new MapHostSearch(searchMapHostsWrapper, maplibregl);
              mapLoaded = true;

              if(['moreFilters', 'trigger-around-me', 'toggleLayout'].indexOf(e.target.id) !== -1){
                document.getElementById(e.target.id).dispatchEvent(new Event('click'));
              }
              else if(e.target.parentElement.id === 'toggleLayout'){
                document.getElementById('toggleLayout').dispatchEvent(new Event('click'));
              }
              else if(e.target.parentElement.id === 'trigger-around-me'){
                document.getElementById('trigger-around-me').dispatchEvent(new Event('click'));
              }
            });
          }
        });
      }
      //on desktop, load the map when page loads
      else{
        import('maplibre-gl').then(maplibregl => {
          new MapHostSearch(searchMapHostsWrapper, maplibregl);
        });
      }
    }
    //otherwise it means that maplibregl was loaded through CDN
    else{
      new MapHostSearch(searchMapHostsWrapper, maplibregl);
    }
  }

  //button to scroll to top
  initScrollToTop();

  //init Swiper
  initSwiper();

  //init logic to remove the worry free block
  initHideWorryFreeBlock();

  initScrollInto();

  //init logic for favorite destinations block
  initHomeSeoBlock();

  new LoadHostExperiences();
});
