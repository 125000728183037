import { ajax } from "../../utils";

/**
 * Initializes the event listener for hiding the worry-free block.
 *
 * @return {undefined} There is no return value.
 */
const initHideWorryFreeBlock = () => {
  const hideWorryFreeBlockButton = document.getElementById('hide-worry-free-block');
  const worryFreeBlock = document.getElementById('worry-free-block');
  if(hideWorryFreeBlockButton && worryFreeBlock){
    hideWorryFreeBlockButton.addEventListener('click', () => {
      //remove the block
      worryFreeBlock.remove();

      //send an ajax request to store in cookie that the user wants to hide the block for futures visits
      ajax(hideWorryFreeBlockButton.dataset.url, () => {}, 'POST', {}, {}, true, false);

      //also store in local storage that the user wants to hide the block for futures visits
      // if (typeof localStorage !== 'undefined') {
      //   localStorage.setItem('hide-worry-free-block', true);
      // }
    });
  }

  //if the user already stored in local storage that he wants to hide the block for futures visits,
  //remove the block in case cookie way didn't work
  // if(typeof localStorage !== 'undefined' && worryFreeBlock && localStorage.getItem('hide-worry-free-block')){ {
  //   worryFreeBlock.remove();
  // }
}

export { initHideWorryFreeBlock }
