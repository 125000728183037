// import { dateToString} from "../utils";
import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";

class MobileSearch {
  constructor() {
    this.wrapper = document.getElementById('mobileFilters');
    this.mobileHeader = document.getElementById("mobile-fullscreen-nav");

    if (this.wrapper) {
      const _this = this;
      this.closeMobileFilters = this.wrapper.querySelector("#closeMobileFilters");
      this.filterOpeners = this.wrapper.querySelectorAll(".filterOpener");
      this.filterClosers = this.wrapper.querySelectorAll(".filterCloser");
      this.datepicker = this.wrapper.querySelector("#mobileFiltersFlatpickr");
      this.dateText = this.wrapper.querySelector("[data-open='date']");
      this.clearDate = this.wrapper.querySelector("#clearDate");
      this.personsInputs = this.wrapper.querySelectorAll(".personsInput");
      this.typesOfExperience = this.wrapper.querySelectorAll(".activity");
      this.langs = this.wrapper.querySelectorAll(".lang");
      this.timesOfDay = this.wrapper.querySelectorAll(".timeOfDay");
      this.closeCurrentFilterInputs = this.wrapper.querySelectorAll(".closeCurrentFilter");

      const mobileSearchOpeners = document.querySelectorAll(".mobileSearch");
      if(mobileSearchOpeners){
        mobileSearchOpeners.forEach(function(mobileSearchOpener){
          mobileSearchOpener.addEventListener("click", () => {
            _this.wrapper.classList.remove("hidden");
            _this.wrapper.querySelector("input[name=local_search]").focus();

            if(_this.mobileHeader && _this.mobileHeader.classList.contains("active")){
              _this.mobileHeader.classList.toggle("active");
            }
          });
        });
      }

      if(this.closeMobileFilters){
        this.closeMobileFilters.addEventListener("click", () => this.wrapper.classList.add("hidden"));
      }

      if (this.filterOpeners) {
        this.filterOpeners.forEach(function (filterOpener) {
          filterOpener.addEventListener("click", () => _this.openFilter(filterOpener));
        });
      }

      if (this.filterClosers) {
        this.filterClosers.forEach(function (filterCloser) {
          filterCloser.addEventListener("click", () => _this.closeFilter(filterCloser));
        });
      }

      if (this.datepicker && this.dateText) {
        this.flatpickr = this.initDatepicker(this.datepicker);
        if (this.clearDate && this.flatpickr) {
          this.clearDate.addEventListener("click", () => this.onClearDate())
        }
      }

      if (this.personsInputs) {
        this.personsInputs.forEach(function (personsInput) {
          personsInput.addEventListener("change", () => _this.onPersonsChange());
        });
      }

      if (this.typesOfExperience) {
        this.typesOfExperience.forEach(function (typeOfExperience) {
          typeOfExperience.addEventListener("click", () => _this.onTypeOfExpClick());
        });
      }

      if (this.langs) {
        this.langs.forEach(function (lang) {
          lang.addEventListener("click", () => _this.onLangClick());
        });
      }

      if (this.timesOfDay) {
        this.timesOfDay.forEach(function (timeOfDay) {
          timeOfDay.addEventListener("click", () => _this.onTimeOfDayClick());
        });
      }

      if(this.closeCurrentFilterInputs){
        this.closeCurrentFilterInputs.forEach(function(closeCurrentFilter){
          closeCurrentFilter.addEventListener("click", () => closeCurrentFilter.closest(".filterToOpen").classList.remove("opened"));
        });
      }
    }
  }

  openFilter(filterOpener) {
    const filterToOpen = this.wrapper.querySelector("#" + filterOpener.dataset.open);
    if (filterToOpen) {
      filterToOpen.classList.add("opened");
    }
  }

  closeFilter(filterCloser) {
    const filterToClose = this.wrapper.querySelector("#" + filterCloser.dataset.close);
    if (filterToClose) {
      filterToClose.classList.remove("opened");
    }
  }

  initDatepicker(datepicker) {
    const _this = this;
    flatpickr.localize(flatpickrLanguages[document.body.dataset.lang.substr(0, 2)]);

    return flatpickr(datepicker, {
      dateFormat: "Y-m-d",
      inline: true,
      // mode: 'range',
      minDate: new Date(),
      // defaultDate: defaultDate,
      // disableMobile: true,
      allowInput: true,
      onChange: function (selectedDates, dateStr) {
        if (selectedDates.length > 0) {
          const locale = document.body.dataset.lang.replace("_", "-");
          const options = { year: 'numeric', month: 'short', day: 'numeric' };

          const date = new Date(selectedDates[0]).toLocaleDateString(locale, options);
          _this.dateText.textContent = date;
          _this.dateText.classList.add("active");

          // const inputDate = dateToString(selectedDates[0]);
          // this.date.value = inputDate;
        }
      },
    });
  }

  onClearDate() {
    this.flatpickr.clear();

    this.dateText.textContent = this.dateText.dataset.initialText;
    this.dateText.classList.remove("active");
  }

  onPersonsChange() {
    const adults = this.wrapper.querySelector("#number-adult");
    const children = this.wrapper.querySelector("#number-child");
    const numberOfPersons = this.wrapper.querySelector("[data-open='persons']");

    let text = "";
    if (adults.value > 0) {
      let adultsTrad = numberOfPersons.dataset.adults.replace("{0}", adults.value);
      let adultTrad = numberOfPersons.dataset.adult;
      text += adults.value > 1 ? adultsTrad : adultTrad;
    }
    if (children.value > 0) {
      if (adults.value > 0) {
        text += ", ";
      }
      let childrenTrad = numberOfPersons.dataset.children.replace("{0}", children.value);
      let childTrad = numberOfPersons.dataset.child;
      text += children.value > 1 ? childrenTrad : childTrad;
    }

    if (adults.value == 0 && children.value == 0) {
      text = numberOfPersons.dataset.noValue;
      numberOfPersons.classList.remove("active");
    }
    else {
      numberOfPersons.classList.add("active");
    }

    numberOfPersons.textContent = text;
  }

  onTypeOfExpClick() {
    const numberOfActivities = this.wrapper.querySelector("[data-open='typesOfExp']");

    const activitiesChecked = this.wrapper.querySelectorAll(".activity:checked");
    if (activitiesChecked) {
      if (activitiesChecked.length > 0) {
        numberOfActivities.textContent = numberOfActivities.dataset.initialText + " • " + activitiesChecked.length;
        numberOfActivities.classList.add("active");
      }
      else {
        numberOfActivities.textContent = numberOfActivities.dataset.initialText;
        numberOfActivities.classList.remove("active");
      }
    }
  }

  onLangClick() {
    const numberOfLangs = this.wrapper.querySelector("[data-open='languages']");

    const langsChecked = this.wrapper.querySelectorAll(".lang:checked");
    if (langsChecked) {
      if (langsChecked.length > 0) {
        let langs = [];
        langsChecked.forEach(function (langChecked) {
          langs.push(langChecked.dataset.name);
        });
        if (langs.length > 2) {
          langs = langs.slice(0, 2);
          langs.push("...");
        }
        numberOfLangs.textContent = langs.join(" • ");
        numberOfLangs.classList.add("active");
      }
      else {
        numberOfLangs.textContent = numberOfLangs.dataset.initialText;
        numberOfLangs.classList.remove("active");
      }
    }
  }

  onTimeOfDayClick() {
    const numberOfTimesOfDay = this.wrapper.querySelector("[data-open='timesOfDay']");

    const timesOfDayChecked = this.wrapper.querySelectorAll(".timeOfDay:checked");
    if (timesOfDayChecked) {
      let text = [];
      if (timesOfDayChecked.length > 0) {
        timesOfDayChecked.forEach(function (timeOfDay) {
          text.push(timeOfDay.dataset.display);
        });

        numberOfTimesOfDay.textContent = text.join(" • ");
        numberOfTimesOfDay.classList.add("active");
      }
      else {
        numberOfTimesOfDay.textContent = numberOfTimesOfDay.dataset.initialText;
        numberOfTimesOfDay.classList.remove("active");
      }
    }
  }
}

export default MobileSearch;
