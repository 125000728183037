const initScrollToTop = () => {
  const button = document.querySelector('[data-scroll-to-top]');
  if(button){
    button.addEventListener('click', () => {
      window.scrollTo(0, 0);
    });

    window.addEventListener('scroll', () => {
      window.scrollY < window.innerHeight
      ? button.classList.remove("lg:block")
      : button.classList.add("lg:block");
    });
  }
}

export { initScrollToTop }
