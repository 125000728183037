import { smoothScrollTo } from "../utils";

const initScrollInto = () => {
  const scrollIntoButtons = document.querySelectorAll('[data-scroll-into-view]');
  if (scrollIntoButtons.length > 0) {
    scrollIntoButtons.forEach(function (button) {
      // console.log(button, button.dataset.to);
      if (button.dataset.to) {
        button.addEventListener('click', function () {
          //Close modal if there's a model opened
          const activeModal = document.querySelector('.modal.active');
          if(activeModal){
            document.body.classList.remove('modal-active');
            activeModal.classList.remove("active");
          }

          const el = document.querySelector(button.dataset.to);
          if (el) {
            // console.log('scrolling to ', el);
            smoothScrollTo(el);
            // el.scrollIntoView({behavior: "smooth", block: "start"});
          }
        })
      }
    });
  }
}

export { initScrollInto }
