import algoliasearch from 'algoliasearch/lite';
import { buildBookingAutoIcon, mapTilesStyle, removeMarkerHighlight } from './map-utils';
import { ajax, dateToString, debounced, formatCurrency, getDistance, getMapRadius, smoothScrollTo } from '../utils';
import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";
import Wish from "./Wish";
import { initSwiper } from "../../modules/common/init-swiper";
import { initOffuscation } from "../../modules/frontv2/link-offuscation";
import { highlighExperienceGalleryItem } from './Search/swiper-utils';
import { initMelchiorSlider } from '../common/init-melchior-slider';
import 'maplibre-gl/dist/maplibre-gl.css';
import Drawer from '../common/Drawer';

class MapHostSearch{
  constructor(wrapper = document, maplibregl = undefined){
    this.wrapper = wrapper;
    if(this.wrapper){
      this.mapContainer = this.wrapper.querySelector('#map-container');
      this.hostsContainer = this.wrapper.querySelector('#hosts-container');
      this.hostsAndTitleContainer = this.wrapper.querySelector('#hosts-title-container');
      this.searchWrapper = this.wrapper.querySelector('#search-wrapper');
      if(this.searchWrapper && this.mapContainer && this.hostsContainer && this.hostsAndTitleContainer && typeof maplibregl !== "undefined"){
        this.maplibregl = maplibregl;
        // this.daysOfWeek = []; //days of week selected to prefilter on algolia when searching with a filter date
        this.alreadyVisitedMarkers = [];
        this.dateFilterHasChanged = false; //flag to check if date filter has changed between 2 searches
        this.currentDateFromSelected = null; //init date from selected
        this.currentDateToSelected = null; //init date to selected
        this.firstRequestDone = false;

        //debounced search to save algolia operations / and for perf
        this.debouncedPerformSearch = debounced(300, () => {
          this.performSearch();
        });

        //culture types to get the translation through data attributes
        this.cultureTypes = ['data-biodynamic', 'data-organic', 'data-reasoned'];

        this.markersPlaced = []; //markers placed on map
        this.currencyInfos = { //all currency infos needed to build prices on list/popups
          locale: document.body.dataset.currencyLocale !== '' ? document.body.dataset.currencyLocale.replace("_", "-") : 'en-US',
          rate: document.body.dataset.currencyRate,
          iso: document.body.dataset.currencyIso
        };
        // this.mobileCardsContainer = this.mapContainer.querySelector('#mobilePopupsContainer');
        this.zoomInButton = this.mapContainer.querySelector('#zoomIn');
        this.zoomOutButton = this.mapContainer.querySelector('#zoomOut');

        this.minPrice = this.wrapper.hasAttribute('data-min-price') ? this.wrapper.dataset.minPrice : 0;
        this.maxPrice = this.wrapper.hasAttribute('data-max-price') ? this.wrapper.dataset.maxPrice : this.hostsContainer.dataset.highestPrice; //max price for the price slider filter

        // this.searchOnMoveCheckbox = this.wrapper.querySelector('#searchOnMove');
        this.triggerManualSearchButton = this.wrapper.querySelector('#triggerSearch');

        this.hostsMapContainer = this.wrapper.querySelector('#hosts-map-container');

        this.lastParams = {};
        this.initialLoadDone = false; //know if initial load was done or not
        // this.playOpenAnimation = true; //by default, plays the animation > when a marker is clicked, scrolls to host list card

        //used to check which message block to show on top of request fallback results
        this.mainFiltersActive = false;
        this.secondaryFiltersActive = false;
        this.mainActiveFiltersList = [];

        // this.displayCountSearchResultsContainer = this.wrapper.querySelector('#count-search-results');

        this.initMap();
        this.initPriceSlider();
        this.initDatepickr();

        //init text on active filters on top bar
        this.initPersonsActiveFilter();
        this.initPriceActiveFilter();
        this.initLangsActiveFilter();
        this.initTypeOfExpActiveFilter();
        // this.initReviewsFilter();

        this.overlayFilters = this.wrapper.querySelector('#overlay-filters');
        this.filterButtons = this.wrapper.querySelectorAll('.btn-dropdown:not(.sort)');
        if(this.overlayFilters && this.filterButtons){
          this.initOverlay();
        }

        this.moreFiltersButton = this.wrapper.querySelector('#moreFilters');
        this.moreFiltersWindow = this.wrapper.querySelector('#filters');
        if(this.moreFiltersButton && this.moreFiltersWindow){
          this.initMoreFiltersToggle();

          if(this.moreFiltersWindow.hasAttribute('data-mobile')){
            this.moreFiltersCountActiveElement = this.moreFiltersButton.querySelector('.active-filters');
            if(this.moreFiltersCountActiveElement){
              this.initComputeActiveFilters();
            }
          }
        }

        this.initClearButtons();
        this.initSaveButtons();

        //toggle map/list in mobile/tablet on button click
        this.toggleLayoutButton = this.wrapper.querySelector('#toggleLayout');
        this.toggleLayoutWrapper = this.wrapper.querySelector('#show-map-list-wrapper');
        this.iconToggleLayout = this.wrapper.querySelector('#icon-toggle-map');
        if(this.toggleLayoutButton && this.toggleLayoutWrapper){
          this.initToggleMap();
        }

        //centers the map on the user position when clicking the button
        this.triggerAroundMeButton = this.wrapper.querySelector('#trigger-around-me');
        if(this.triggerAroundMeButton){
          this.aroundMeMarkerPlaced = false;
          this.initAroundMe();
        }

        //close more filters on buttons click
        this.closeFiltersButtons = this.wrapper.querySelectorAll('.closeFilters');
        if(this.closeFiltersButtons){
          this.initCloseMoreFiltersButtons();
        }

        this.sortButton = this.wrapper.querySelector('#sort-button');
        this.sortButtonText = this.wrapper.querySelector('#sort-button span');
        this.sortOptions = this.wrapper.querySelectorAll('.dropdown-container.sort input');
        if(this.sortButtonText && this.sortOptions && this.sortButton){
          this.sortOption = this.sortButton.dataset.initialSortType; //server side base sort
          this.initSorting();
        }

        //add/remove the card highlight depending on mouse hover
        this.initCardMouseHover();

        //On kiosk, add a blue point at the center of the map
        if(this.searchWrapper.hasAttribute('data-kiosk')){
          this.initCenterPointOnKiosk();
        }

        //if user comes from around me feature, center the map on the user's current position
        if(this.searchWrapper.hasAttribute('data-trigger-around-me')){
          this.performSearchOnAroundMe();
        }

        //init locate buttons
        this.initLocateButtons();
      }
    }
  }

  initPriceSlider(){
    //init input type range logic (min/max)
    initMelchiorSlider();

    this.priceRangeContainer = this.wrapper.querySelector('#price-slider-wrapper .range_container');
    this.minPriceInput = this.wrapper.querySelector('#price-slider-wrapper .fromInput');
    this.maxPriceInput = this.wrapper.querySelector('#price-slider-wrapper .toInput');
  }


  initDatepickr(){
    flatpickr.localize(flatpickrLanguages[document.body.dataset.lang.substr(0, 2)]);

    this.flatpickrInstance = null;
    this.inlineDate = this.wrapper.querySelector("#inlineDate");

    //on desktop, init flatpickr when clicking on the filter that displays the calendar
    //otherwise, the init doesn't work correctly since the calendar is not visible
    // let defaultDate = new Date();
    // if(this.wrapper.dataset.date && this.wrapper.dataset.date !== 'false'){
    //   defaultDate = new Date(this.wrapper.dataset.date);
    // }

    if (this.inlineDate) {
      this.dateWrapper = this.inlineDate.parentElement;

      this.initFlatpickrInstance();
      this.flatpickrInitedAtClick = false;
      this.inlineDate.addEventListener("click", () => {
        if(!this.flatpickrInitedAtClick){
          this.initFlatpickrInstance();
          this.flatpickrInitedAtClick = true;
        }
      });
    }
    //on mobile, just init the flatpickr
    else{
      this.dateWrapper = this.wrapper.querySelector('#date-wrapper');
      this.initFlatpickrInstance();
    }
  }

  initFlatpickrInstance(){
    const _this = this;

    const flatpickrElt = _this.wrapper.querySelector(".flatpickr");
    if (flatpickrElt/* && !_this.flatpickrInstance*/) {
      let now = new Date();
      now.setHours(0, 0, 0);

      _this.flatpickrInstance = flatpickr(flatpickrElt, {
        inline: true,
        minDate: now,
        dateFormat: "Y-m-d",
        // defaultDate: defaultDate,
        showMonths: flatpickrElt.dataset.showMonths,
        mode: "range",
        onChange: function (selectedDates, dateStr, instance) {
          if (selectedDates.length && selectedDates[0]) {
            _this.currentDateFromSelected = selectedDates[0];

            //if we are on a kiosk, save the date filter into session for global filters
            if(_this.searchWrapper.hasAttribute('data-kiosk')){
              _this.globalFilterDate = new Date(selectedDates[0]);
              _this.updateSession();
            }

            const locale = document.body.dataset.lang.replace("_", "-");
            const options = { month: 'short', day: 'numeric' };
            const dateFromText = new Date(selectedDates[0]).toLocaleDateString(locale, options);

            //display the date text in the UI (ex: 10 jan. - 13 jan.)
            if(_this.inlineDate){
              let dateText = dateFromText;
              if(selectedDates[1] && dateToString(selectedDates[0]) !== dateToString(selectedDates[1])){
                const dateToText = new Date(selectedDates[1]).toLocaleDateString(locale, options);
                dateText = dateFromText + ' - ' + dateToText;
              }

              _this.inlineDate.textContent = dateText;
              _this.inlineDate.classList.add("active");
            }

            //recompute nb of active filters
            if(_this.moreFiltersCountActiveElement){
              _this.computeActiveFiltersCount();
            }
          }

          //make sure that the user can filter only on 4 days max
          if(selectedDates.length == 1) {
            //reset the date to
            _this.currentDateToSelected = null;

            let threeDaysLater = new Date(
                selectedDates[0].getTime() + 86400000 * 3
            );

            instance.config.minDate = selectedDates[0].toUTCString();
            instance.config.maxDate = threeDaysLater.toUTCString();
          }
          else if (selectedDates.length == 2) {
            _this.currentDateToSelected = selectedDates[1];

            instance.config.minDate = now.toUTCString();
            instance.config.maxDate = undefined;
          }

          _this.dateFilterHasChanged = true;
        },
        onReady: function (a, b, f) {
          if (_this.wrapper.hasAttribute('data-date') && _this.wrapper.dataset.date !== 'false'){
            f.setDate(f.config.defaultDate, true);
          }

          //init the clear button
          _this.clearDateButton = _this.wrapper.querySelector('#clear-date');
          if(_this.clearDateButton){
            _this.clearDateButton.addEventListener('click', () => {
              _this.clearDate();
            });
          }
        }
      });
    }
  }

  //handle active text on filters
  initPersonsActiveFilter(){
    this.adults = 0;
    this.children = 0;
    this.adultsInput = this.wrapper.querySelector('#number-adults');
    this.childrenInput = this.wrapper.querySelector('#number-children');

    this.personsText = this.wrapper.querySelector('#inlinePersons');
    this.personsInput = this.wrapper.querySelectorAll('.personsInput');
    if(this.personsInput){
      this.personsInput.forEach(input => {
        input.addEventListener('change', () => {
          if(input.dataset.type == 'adult') this.adults = input.value;
          else if(input.dataset.type == 'child') this.children = input.value;

          if(this.personsText){
            let text = "";
            if (this.adultsInput.value > 0) {
              let adultsTrad = this.personsText.dataset.adults.replace("{0}", this.adultsInput.value);
              let adultTrad = this.personsText.dataset.adult;
              text += this.adultsInput.value > 1 ? adultsTrad : adultTrad;
            }
            if (this.childrenInput.value > 0) {
              if (this.adultsInput.value > 0) {
                text += ", ";
              }
              let childrenTrad = this.personsText.dataset.children.replace("{0}", this.childrenInput.value);
              let childTrad = this.personsText.dataset.child;
              text += this.childrenInput.value > 1 ? childrenTrad : childTrad;
            }

            if (this.adultsInput.value == 0 && this.childrenInput.value == 0) {
              text = this.personsText.dataset.noValue;
              this.personsText.classList.remove("active");
            }
            else {
              this.personsText.classList.add("active");
            }

            this.personsText.textContent = text;
          }

          //on kiosk, update session vars for global filters
          if(this.searchWrapper.hasAttribute('data-kiosk')){
            this.updateSession();
          }
        });

        input.dispatchEvent(new Event('change'));
      });
    }
  }

  initPriceActiveFilter(){
    if (this.priceRangeContainer) {
      this.inlinePriceDisplay = this.wrapper.querySelector("#inline-price-text");
      this.priceRangeContainer.addEventListener("sliderValueChanged", (e) => {
        this.minPrice = e.detail.fromValue;
        this.maxPrice = e.detail.toValue;

        if (this.inlinePriceDisplay) {
          let formattedMinPrice = formatCurrency(this.minPrice, this.currencyInfos.locale, this.currencyInfos.iso);
          let formattedMaxPrice = formatCurrency(this.maxPrice, this.currencyInfos.locale, this.currencyInfos.iso);
          this.inlinePriceDisplay.textContent = formattedMinPrice + " • " + formattedMaxPrice;
          this.inlinePriceDisplay.classList.add("active");
        }

        if(this.moreFiltersCountActiveElement){
          this.computeActiveFiltersCount();
        }
      });

      if (this.inlinePriceDisplay && (this.minPrice != 0 || this.maxPrice != this.hostsContainer.dataset.highestPrice)) {
        let formattedMinPrice = formatCurrency(this.minPrice, this.currencyInfos.locale, this.currencyInfos.iso);
        let formattedMaxPrice = formatCurrency(this.maxPrice, this.currencyInfos.locale, this.currencyInfos.iso);
        this.inlinePriceDisplay.textContent = formattedMinPrice + " • " + formattedMaxPrice;
        this.inlinePriceDisplay.classList.add("active");
      }
    }
  }

  initLangsActiveFilter(){
    this.activeLangCheckboxes = this.wrapper.querySelectorAll('.lang-filter:checked').length;
    this.langCheckboxes = this.wrapper.querySelectorAll('.lang-filter');
    this.langFilterText = this.wrapper.querySelector('#lang-active-text');
    this.langs = [];
    if(this.langCheckboxes && this.langFilterText){
      this.langCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', () => {
          if(checkbox.checked) this.activeLangCheckboxes++;
          else this.activeLangCheckboxes--;

          this.handleLangsActiveText();

          //in case of kiosk, update session for global filters
          // if(this.searchWrapper.hasAttribute('data-kiosk')){
          //   const activeLangs = this.wrapper.querySelectorAll('.lang-filter:checked');
          //   if(activeLangs){
          //     this.langs = [];
          //     activeLangs.forEach(lang => {
          //       this.langs.push(lang.dataset.id);
          //     });

          //     this.updateSession();
          //   }
          // }
        });
      });

      this.handleLangsActiveText();
    }
  }

  handleLangsActiveText(){
    if(this.activeLangCheckboxes > 0){
      this.langFilterText.textContent = this.langFilterText.dataset.initialText + ' • ' + this.activeLangCheckboxes;
      this.langFilterText.classList.add('active');
    }
    else{
      this.langFilterText.textContent = this.langFilterText.dataset.initialText;
      this.langFilterText.classList.remove('active');
    }
  }

  initTypeOfExpActiveFilter(){
    this.activeTypeOfExpCheckboxes = this.wrapper.querySelectorAll('.type-of-exp-filter:checked').length;
    this.typeOfExpCheckboxes = this.wrapper.querySelectorAll('.type-of-exp-filter');
    this.typeOfExpFilterText = this.wrapper.querySelector('#type-of-exp-text');
    if(this.typeOfExpCheckboxes && this.typeOfExpFilterText){
      this.typeOfExpCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', () => {
          if(checkbox.checked) this.activeTypeOfExpCheckboxes++;
          else this.activeTypeOfExpCheckboxes--;

          this.handleActiveTypeOfExpText();
        });
      });

      this.handleActiveTypeOfExpText();
    }
  }

  // initReviewsFilter(){
  //   let containerReviews = document.getElementById("container-reviews");
  //   if(!containerReviews) return;

  //   let buttons = containerReviews.querySelectorAll("button");
  //   buttons.forEach((button) => {
  //     button.addEventListener("click", () => {
  //       buttons.forEach((button) => {
  //         button.classList.remove("active");
  //       });
  //       button.classList.add("active");
  //     });
  //   });
  // }

  handleActiveTypeOfExpText(){
    if(this.activeTypeOfExpCheckboxes > 0){
      this.typeOfExpFilterText.textContent = this.typeOfExpFilterText.dataset.initialText + ' • ' + this.activeTypeOfExpCheckboxes;
      this.typeOfExpFilterText.classList.add('active');
    }
    else{
      this.typeOfExpFilterText.textContent = this.typeOfExpFilterText.dataset.initialText;
      this.typeOfExpFilterText.classList.remove('active');
    }
  }

  initOverlay(){
    this.triggerSearchOnFilterDropdownLeave();

    this.filterButtons.forEach(button => {
      button.addEventListener('click', () => {
        // this.displayOverlay();
        // button.parentElement.classList.add('z-50');


        if(!button.parentElement.classList.contains('active')){
          this.removeOverlay();
        }
      });
    });

    this.overlayFilters.addEventListener('click', () => {
      this.removeOverlay();
      this.removeMoreFilters();
    });
  }

  initMoreFiltersToggle(){
    this.moreFiltersButton.addEventListener('click', () => {
      this.displayOverlay();
      this.displayMoreFilters();
    });
  }

  // toggleMoreFilters(){
  //   this.moreFiltersWindow.classList.toggle('opened');
  // }

  displayMoreFilters(){
    this.moreFiltersWindow.classList.add('opened');
  }

  removeMoreFilters(){
    this.moreFiltersWindow.classList.remove('opened');
  }

  displayOverlay(){
    this.overlayFilters.classList.remove('invisible');
  }

  /**
   * Remove the overlay and perform a search after debouncing.
   */
  removeOverlay(){
    this.overlayFilters.classList.add('invisible');
    this.debouncedPerformSearch();

    const dropdownFilters = this.wrapper.querySelectorAll('.dropdown-filter.z-50');
    if(dropdownFilters) dropdownFilters.forEach(dropdownFilter => dropdownFilter.classList.remove('z-50'));
  }

  /**
   * Triggers search when the filter dropdown is left. (user clicks outside the dropdown)
   */
  triggerSearchOnFilterDropdownLeave(){
    var observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          if(!mutation.target.classList.contains('active')){
            this.debouncedPerformSearch();
          }
        }
      });
    });

    this.filterButtons.forEach(button => {
      observer.observe(button.parentNode, {
        attributes: true,
        attributeFilter: ['class']
      });
    });
  }

  initClearButtons(){
    this.clearLanguagesButton = this.wrapper.querySelector('#clear-languages');
    if(this.clearLanguagesButton){
      this.clearLanguagesButton.addEventListener('click', () => {
        this.activeLangCheckboxes = 0;
        this.langCheckboxes.forEach(checkbox => checkbox.checked = false);
        this.langFilterText.classList.remove('active');
        this.langFilterText.textContent = this.langFilterText.dataset.initialText;

        const dropdown = this.clearLanguagesButton.closest('.dropdown.active');
        if(dropdown) dropdown.classList.remove('active');

        this.removeOverlay();

        // if(this.searchWrapper.hasAttribute('data-kiosk')){
        //   this.langs = [];
        //   this.updateSession();
        // }
      });
    }

    this.clearTypesOfExpButton = this.wrapper.querySelector('#clear-types-of-exp');
    if(this.clearTypesOfExpButton){
      this.clearTypesOfExpButton.addEventListener('click', () => {
        this.activeTypeOfExpCheckboxes = 0;
        this.typeOfExpCheckboxes.forEach(checkbox => checkbox.checked = false);
        this.typeOfExpFilterText.textContent = this.typeOfExpFilterText.dataset.initialText;
        this.typeOfExpFilterText.classList.remove('active');

        const dropdown = this.clearTypesOfExpButton.closest('.dropdown.active');
        if(dropdown) dropdown.classList.remove('active');

        this.removeOverlay();
      });
    }
  }

  clearPrice(){
    this.maxPrice = this.hostsContainer.dataset.highestPrice;
    this.minPrice = 0;
    this.priceRangeContainer.dispatchEvent(new Event('reset'));

    if(this.inlinePriceDisplay){
      this.inlinePriceDisplay.textContent = this.inlinePriceDisplay.dataset.initialText;
      this.inlinePriceDisplay.classList.remove('active');
    }
  }

  clearPersons(){
    this.adultsInput.value = 0;
    this.childrenInput.value = 0;

    this.personsInput.forEach(input => {
      input.dispatchEvent(new Event('change'));
    });
  }

  clearLangs(){
    if(this.clearLanguagesButton){
      this.clearLanguagesButton.dispatchEvent(new Event('click'));
    }
    else{
      this.langCheckboxes.forEach(checkbox => checkbox.checked = false);
    }
  }

  clearDate(){
    this.flatpickrInstance.clear();

    let now = new Date();
    this.flatpickrInstance.config.minDate = now.toUTCString();
    this.flatpickrInstance.config.maxDate = undefined;

    //in case we're on a kiosk
    //delete the date filter from localstorage
    if(this.searchWrapper.hasAttribute('data-kiosk')){
      this.globalFilterDate = null;
      this.updateSession();
    }

    if(this.inlineDate){
      this.inlineDate.textContent = this.inlineDate.dataset.initialText;
      this.inlineDate.classList.remove("active");
    }

    // this.daysOfWeek = [];

    this.dateFilterHasChanged = true;

    this.currentDateFromSelected = null;
    this.currentDateToSelected = null;
    // this.removeOverlay();

    //recompute nb of active filters
    if(this.moreFiltersCountActiveElement){
      this.computeActiveFiltersCount();
    }
  }

  clearTypeOfExp(){
    if(this.clearTypesOfExpButton){
      this.clearTypesOfExpButton.dispatchEvent(new Event('click'));
    }
    else{
      this.typeOfExpCheckboxes.forEach(checkbox => checkbox.checked = false);
    }
  }

  clearSecondaryFilters(){
    const filtersToReset = this.wrapper.querySelectorAll(".filter:checked");
    if (filtersToReset) {
      filtersToReset.forEach(function (filterToReset) {
        filterToReset.checked = false;
      });
    }

    this.instantBook.checked = false;
    this.privateGroup.checked = false;

    if(!this.wrapper.hasAttribute('data-only-excellence-hosts')){
      this.excellenceAward.checked = false;
    }

    const visibilityElt = this.wrapper.querySelector('#more-both');
    if(visibilityElt) visibilityElt.checked = true;

    const cancellationElt = this.wrapper.querySelector('#cancellation-all');
    if(cancellationElt) cancellationElt.checked = true;

    // Filter reviews
    const reviewAllElt = this.wrapper.querySelector('#review-all');
    if(reviewAllElt) reviewAllElt.checked = true;
  }

  initSaveButtons(){
    this.saveButtons = this.wrapper.querySelectorAll('.save');
    if(this.saveButtons){
      this.saveButtons.forEach(button => {
        button.addEventListener('click', () => {
          button.closest('.dropdown.active').classList.remove('active');

          this.removeOverlay();
        });
      });
    }
  }

  initMap(){
    this.map = new this.maplibregl.Map({
      // accessToken: 'pk.eyJ1Ijoid2luYWxpc3QiLCJhIjoiY2p0azd3b2Q5MTQzMjN5bzZidmU5cDF6biJ9.65pAPF4tbn5Geqg7JhvCFQ',
      name: 'Winalist',
      container: 'map-container',
      style: mapTilesStyle,
      zoom: this.mapContainer.dataset.zoom ?? 8,
      center: [this.mapContainer.dataset.lng, this.mapContainer.dataset.lat], //default to Champagne lat/lng,
      dragPan: !this.searchWrapper.hasAttribute('data-kiosk'),
      doubleClickZoom: !this.searchWrapper.hasAttribute('data-kiosk'),
      touchZoomRotate: !this.searchWrapper.hasAttribute('data-kiosk')
    });
    this.map.scrollZoom.disable();

    this.bindZoomButtons();

    this.algolia = this.getAlgoliaClient();

    this.map.on('dragend', () => {
      this.triggerManualSearchButton.classList.remove('hidden');
    });

    this.map.on('moveend', () => {
      this.triggerManualSearchButton.classList.remove('hidden');
    });

    this.triggerManualSearchButton.addEventListener('click', () => {
      this.debouncedPerformSearch();
      this.triggerManualSearchButton.classList.add('hidden');
    });

    this.map.on('click', () => {
      removeMarkerHighlight();
    });

    //if needed, place a marker at a point lat/lng
    //for now, used on cities landing for city location
    if(this.wrapper.hasAttribute('data-point-lat') && this.wrapper.hasAttribute('data-point-lng')){
      let div = document.createElement('div');
      div.className = 'location-marker';
      new this.maplibregl.Marker({
        element: div
      })
      .setLngLat([this.wrapper.dataset.pointLng, this.wrapper.dataset.pointLat])
      .addTo(this.map);
    }

    //On initial load, we use hosts loaded from server side
    if(!this.initialLoadDone){
      //get the json with hosts loaded from back (used once so no need to store it in this)
      const hostsJson = document.getElementById('hosts-json');
      if(hostsJson){
        this.hosts = JSON.parse(hostsJson.innerHTML);
        this.setDistanceProperty();
        this.updateMarkersOnMap(); //updates markers on map

        if(this.hosts.length === 0){
          this.hostsContainer.textContent = this.hostsContainer.dataset.noHostsFound;
          this.hostsContainer.classList.add('font-semibold');
        }
      }

      this.initialLoadDone = true;
    }

    if(this.searchWrapper.hasAttribute('data-trigger-search-on-page-load')){
      this.debouncedPerformSearch();
    }
  };

  bindZoomButtons(){
    if(this.map && this.zoomInButton && this.zoomOutButton){
      this.zoomInButton.addEventListener('click', () => {
        this.map.zoomIn();
      });
      this.zoomOutButton.addEventListener('click', () => {
        this.map.zoomOut();
      });
    }
  }

  getAlgoliaClient(){
    const env = document.body.dataset.env;
    const lang = document.body.dataset.lang.substr(0, 2);

    let prefix = '';
    if(env === 'localhost' || env == 'staging'){
      prefix = 'localhost_';
    }
    let experiences_index = prefix + 'experiences_' + lang;

    const client = algoliasearch('AK7I6BLE3J', '6b23703cf5bc843f0015aa31634bdeef');
    return client.initIndex(experiences_index);
  }

  //Perform the search through algolia
  performSearch(){
    let filters = this.getActiveFilters();
    let params = {
      query: '',
      hitsPerPage: 200,
      attributesToRetrieve: '*',
      responseFields: '*',
      numericFilters: filters.numericFilters,
      facetFilters: filters.facetFilters
    };

    let pos = this.map.getCenter();
    params.aroundLatLng = pos.lat + "," + pos.lng;
    let bounds = this.map.getBounds();
    params.aroundRadius = getMapRadius(bounds._ne, bounds._sw);

    const _this = this;

    //make sure to not trigger a search with the same params as before to avoid useless algolia operations
    if((JSON.stringify(this.lastParams) !== JSON.stringify(params)) || this.dateFilterHasChanged){
      //add overlay showing that a search is ocurring
      this.hostsContainer.classList.add('overlay-on');

      this.dateFilterHasChanged = false;

      //get experiences found with experiences.hits
      this.algolia.search(params, (err, experiences) => {
        _this.searchDone(err, experiences, params);
      });
    }
  }

  //isFallbackRequest is the request made if no hosts are found with1 the initial filters
  searchDone(err, experiences, params, isFallbackRequest = false){
    if(!isFallbackRequest)
      this.lastParams = params;

    if (err) throw err;

    if(!isFallbackRequest && this.currentDateFromSelected !== null){ //if user chose a date filter
      this.checkAvailability(experiences.hits); //we check if every experience is actually available (has at least one timeslot opened at the specified date)
    }

    //since we filter on experiences but we need to display hosts
    //filter here to get only hosts
    this.hosts = [];
    experiences.hits.forEach((experience) => {
      if(typeof experience.host.id !== 'undefined'){
        if(typeof this.hosts[experience.host.id] === 'undefined'){
          this.hosts[experience.host.id] = experience.host;
          this.hosts[experience.host.id]['can_book_auto'] = experience.booking_auto;
          this.hosts[experience.host.id]['bookedTimes'] = experience.bookedTimes;
          this.hosts[experience.host.id]['count_review'] = experience.host.count_review;
          this.hosts[experience.host.id]['first_exp_published_timestamp'] = experience.host.first_exp_published_timestamp;
        }
        else{
          if(experience.booking_auto !== 0) {
            this.hosts[experience.host.id]['can_book_auto'] = experience.booking_auto;
          }
          this.hosts[experience.host.id]['bookedTimes'] += experience.bookedTimes;
        }

        // Attributes for slider host images
        if (typeof this.hosts[experience.host.id].experiences === 'undefined') {
          this.hosts[experience.host.id].experiences = [];
        }

        if (typeof this.hosts[experience.host.id].appellations_ids === 'undefined') {
          this.hosts[experience.host.id].appellations_ids = [];

          // loop through appellations to push in the array
          if(typeof experience.host.appellations !== 'undefined' && experience.host.appellations.length > 0){
            experience.host.appellations.forEach(appellation => {
              this.hosts[experience.host.id].appellations_ids.push(appellation.id);
            });
          }
        }

        this.hosts[experience.host.id].experiences.push({
          name: experience.name,
          coverCdnUrl: experience.cover_cdn_url,
          coverCdnPath: experience.cover_cdn_path,
          price: experience.price_in_euros,
          url: experience.url,
          activity: experience.activity,
          average_review: experience.review,
          count_reviews: experience.nb_reviews,
          durationAlgolia: experience.duration_algolia,
          city: experience.city,
          use_group_pricing: experience.use_group_pricing
        });

        if(experience.hasOwnProperty('pictures') && experience.pictures.length > 0){
          if (typeof this.hosts[experience.host.id].pictures === 'undefined') {
            this.hosts[experience.host.id].pictures = [];
          }

          experience.pictures.forEach(picture => {
            this.hosts[experience.host.id].pictures.push({
              cdnUrl: picture.pictureCdnUrl,
              cdnPath: picture.pictureCdnPath
            });
          });
        }
      }
    });

    // this.displayCountSearchResults(isFallbackRequest);

    if(!isFallbackRequest && this.hosts.length == 0){
      let params = this.getBlankParams();
      return this.algolia.search(params, (err, experiences) => {
        this.searchDone(err, experiences, params, true);
      });
    }

    //sort hosts depending on user's choice
    if(this.hosts.length !== 0){
      this.setDistanceProperty();
      this.sortHosts();
    }

    this.updateHostsList(isFallbackRequest); //updates the hosts list
    this.updateMarkersOnMap(); //updates markers on map

    //if no hosts are found, display "no hosts found" message on the UI
    if(this.hosts.length === 0){
      this.hostsContainer.textContent = this.hostsContainer.dataset.noHostsFound;
      this.hostsContainer.classList.add('font-semibold');
    }
    else{
      //replace by kiosk host url if on kiosk page
      if(this.searchWrapper.hasAttribute('data-kiosk-host-url')){
        this.hosts.forEach((host) => {
          host.url = this.searchWrapper.dataset.kioskHostUrl.replace('%id%', host.id);
        });
      }

      this.hostsContainer.classList.remove('font-semibold');
    }

    let triggerSearchOnPageLoad = this.searchWrapper.hasAttribute('data-trigger-search-on-page-load');
    if((this.firstRequestDone && triggerSearchOnPageLoad) || !triggerSearchOnPageLoad){
      smoothScrollTo(document.querySelector('#search-map-hosts')); //make sure we scroll full top when a new search is triggered
    }

    //reset main/secondary flags
    //they are checked before a search performs
    //so we need to reset them at the end of every search
    this.mainFiltersActive = false;
    this.secondaryFiltersActive = false;
    this.mainActiveFiltersList = [];
    if(!this.firstRequestDone){
      this.firstRequestDone = true;
    }

    this.hostsContainer.classList.remove('overlay-on'); //remove search overlay
  }

  getActiveFilters(){
    // let onMobile = window.matchMedia("(max-width: 640px)").matches;
    let params = {};
    params.numericFilters = [];
    params.facetFilters = [];

    this.getTypesOfExp(params.facetFilters);
    this.getLanguages(params.facetFilters);
    this.getNumberOfPersons(params.numericFilters);
    this.getPrice(params.numericFilters);
    // this.getDays(params.facetFilters);

    //FILTERS THAT ARE IN MORE FILTERS WINDOW (DESKTOP)
    this.getTimesOfDay(params.numericFilters);
    this.getInstantBook(params.numericFilters);
    this.getReviews(params.numericFilters);
    this.getTypesOfHost(params.facetFilters);
    this.getServices(params.facetFilters);
    this.getCancellation(params.numericFilters);
    this.getVisibility(params.numericFilters);

    //if region, add the region ID
    // if(this.wrapper.hasAttribute('data-region-id')){
    //   params.numericFilters.push("region_id = " + this.wrapper.dataset.regionId);
    // }
    // else if(this.wrapper.hasAttribute('data-appellation-id')){
    //   params.facetFilters.push(['host.appellations.id:' + this.wrapper.dataset.appellationId]);
    // }

    if(this.wrapper.hasAttribute('data-only-excellence-hosts')){
      params.numericFilters.push('host.has_excellence_award = ' + 1);
    }
    else{
      this.getExcellenceAward(params.numericFilters);
    }

    if(this.searchWrapper.hasAttribute('data-hosts-to-filter')){
      this.addKioskHosts(params);
    }

    return params;
  }

  //used only for kiosk hosts
  addKioskHosts(params){
    let hostsToFilter = JSON.parse(this.searchWrapper.dataset.hostsToFilter);
    let hosts = [];
    hostsToFilter.forEach(host => {
      hosts.push('host.id:' + host);
    });

    params.facetFilters.push(hosts);
  }

  //used for fallback request when no initial results
  getBlankParams(){
    let pos = this.map.getCenter();
    let params = {
      query: '',
      hitsPerPage: 20, //
      attributesToRetrieve: '*',
      responseFields: '*',
      numericFilters: [],
      facetFilters: [],
      aroundLatLng: pos.lat + "," + pos.lng,
      aroundRadius: 200000 //200KM
    };

    //in case it is kiosk
    if(this.searchWrapper.hasAttribute('data-hosts-to-filter')){
      this.addKioskHosts(params);
    }

    return params;
  }

  getTypesOfExp(facetFilters){
    let typesOfExpArr = [];

    let typesOfExpActive = this.wrapper.querySelectorAll(".type-of-exp-filter:checked");
    if (typesOfExpActive) {
      typesOfExpActive.forEach(function (typeOfExp) {
        let typeOfExpID = typeOfExp.dataset.id;
        if (typeOfExpID) {
          typesOfExpArr.push('activitys:' + typeOfExpID);
        }
      });
    }

    if (typesOfExpArr.length > 0) {
      facetFilters.push(typesOfExpArr);
      this.mainFiltersActive = true;
      if(this.mainActiveFiltersList.indexOf('typeofexp') === -1){
        this.mainActiveFiltersList.push('typeofexp')
      }
    }
  }

  getLanguages(facetFilters){
    let languagesArr = [];
    this.langs = [];

    let languagesActive = this.wrapper.querySelectorAll(".lang-filter:checked");
    if (languagesActive) {
      languagesActive.forEach((language) => {
        let languageISO = language.dataset.iso;
        if (languageISO) {
          languagesArr.push('langs:' + languageISO);
        }

        this.langs.push(language.dataset.id);
      });
    }

    if (languagesArr.length > 0) {
      facetFilters.push(languagesArr);
      this.mainFiltersActive = true;
      if(this.mainActiveFiltersList.indexOf('langs') === -1){
        this.mainActiveFiltersList.push('langs')
      }
    }
  }

  getNumberOfPersons(numericFilters){
    const adultsInput = this.wrapper.querySelector('#number-adults');
    const childrenInput = this.wrapper.querySelector('#number-children');

    if(adultsInput && childrenInput){
      let persons = parseInt(adultsInput.value) + parseInt(childrenInput.value);
      this.spots = persons;

      if (persons > 0) {
        numericFilters.push("booking_min <= " + persons);
        numericFilters.push("booking_max >= " + persons);
        this.mainFiltersActive = true;

        if(this.mainActiveFiltersList.indexOf('persons') === -1){
          this.mainActiveFiltersList.push('persons')
        }
      }
    }
  }

  getPrice(numericFilters){
    numericFilters.push('price_in_euros >= ' + this.minPrice);
    numericFilters.push('price_in_euros <= ' + this.maxPrice);

    if(this.maxPrice != this.hostsContainer.dataset.highestPrice || this.minPrice != 0){
      this.mainFiltersActive = true;
      if(this.mainActiveFiltersList.indexOf('price') === -1){
        this.mainActiveFiltersList.push('price')
      }
    }
  }

  // getDays(facetFilters){
  //   let timeSlots = [];

  //   if (this.daysOfWeek.length > 0) {
  //     this.daysOfWeek.forEach(dayOfWeek => {
  //       if(dayOfWeek === 0){
  //         dayOfWeek = 7;
  //       }

  //       timeSlots.push("time_slots:" + dayOfWeek + "ma");
  //       timeSlots.push("time_slots:" + dayOfWeek + "m");
  //       timeSlots.push("time_slots:" + dayOfWeek + "a");
  //     });

  //     if (timeSlots.length) {
  //       facetFilters.push(timeSlots);
  //       this.mainFiltersActive = true;
  //       if(this.mainActiveFiltersList.indexOf('days') === -1){
  //         this.mainActiveFiltersList.push('days')
  //       }
  //     }
  //   }
  // }

  getReviews(numericFilters){
    const averageReviewButton = this.wrapper.querySelector("input[name='filter-reviews']:checked");
    if (averageReviewButton && averageReviewButton.dataset.averageReview !== 'all') {
      let averageReview = parseFloat(averageReviewButton.dataset.averageReview);

      numericFilters.push("host.average_review >= " + averageReview);
      this.secondaryFiltersActive = true;
    }
  }

  getTypesOfHost(facetFilters){
    let typesOfHost = [];

    let typesOfHostActive = this.wrapper.querySelectorAll("#filters #typesOfHost .filter:checked");
    if (typesOfHostActive) {
      typesOfHostActive.forEach(function (typeOfHost) {
        let typeOfHostID = typeOfHost.dataset.id;
        if (typeOfHostID) {
          typesOfHost.push('categories:' + typeOfHostID);
        }
      });
    }

    if (typesOfHost.length > 0) {
      facetFilters.push(typesOfHost);
      this.secondaryFiltersActive = true;
    }
  }

  getServices(facetFilters){
    const _this = this;
    let services = [];

    let servicesActive = this.wrapper.querySelectorAll("#filters #services .filter:checked");
    if (servicesActive) {
      servicesActive.forEach(function (service) {
        let serviceID = service.dataset.id;
        if (serviceID) {
          services.push('services:' + serviceID);
          _this.secondaryFiltersActive = true;
        }
      });
    }

    if (services.length > 0) {
      facetFilters.push(services);
    }
  }

  getInstantBook(numericFilters){
    this.instantBook = this.wrapper.querySelector("#instantBook");
    if (this.instantBook && this.instantBook.checked) {
      numericFilters.push('booking_auto = 1');
      this.secondaryFiltersActive = true;
    }
  }

  getVisibility(numericFilters){
    this.privateGroup = this.wrapper.querySelector("#is-private");
    if (this.privateGroup && this.privateGroup.checked) {
      numericFilters.push("private = 1");
      this.secondaryFiltersActive = true;
    }
  }

  getCancellation(numericFilters){
    const visibility = this.wrapper.querySelector("input[name='cancellation']:checked");
    if (visibility && visibility.value === "full_refund_under_24") {
      numericFilters.push("full_refund_under_24 = 1");
      this.secondaryFiltersActive = true;
    }
  }

  getTimesOfDay(numericFilters){
    const _this = this;

    let timesOfDayActive = this.wrapper.querySelectorAll("#filters #timesOfDay .filter:checked");
    if (timesOfDayActive) {
      timesOfDayActive.forEach(function (timeOfDay) {
        let algoliaAttribute = timeOfDay.dataset.algoliaAttribute;
        if (algoliaAttribute) {
          numericFilters.push(algoliaAttribute + ' = 1');
          _this.secondaryFiltersActive = true;
        }
      });
    }
  }

  getExcellenceAward(numericFilters){
    this.excellenceAward = this.wrapper.querySelector("#excellenceAward");
    if (this.excellenceAward && this.excellenceAward.checked) {
      numericFilters.push('host.has_excellence_award = 1');
      this.secondaryFiltersActive = true;
    }
  }

  sortHosts(){
    let propertyToSortOn = '';
    let locationType = '';
    let order = 'DESC';
    if(this.searchWrapper.hasAttribute('data-location-type') && this.searchWrapper.dataset.locationType != ''){
      locationType = this.searchWrapper.dataset.locationType;
    }

    if(this.sortOption === 'popularity'){
      propertyToSortOn = 'count_review';
      order = 'DESC';
    }
    else if(this.sortOption === 'cheapest'){
      propertyToSortOn = 'lowest_price_in_euros';
      order = 'ASC';
    }
    else if(this.sortOption === 'most-expensive'){
      propertyToSortOn = 'lowest_price_in_euros';
      order = 'DESC';
    }
    else if(this.sortOption === 'distance'){
      propertyToSortOn = 'distance';
      order = 'ASC';
    }

    let date = new Date();
    let fifteenDaysAgo = date.setDate(date.getDate() - 15);
    let timestampFifteenDaysAgo = Math.floor(fifteenDaysAgo / 1000);
    let appellationId = this.wrapper.hasAttribute('data-appellation-id') ? parseInt(this.wrapper.dataset.appellationId) : false;

    this.hosts.sort((a, b) => {
      const aFeatured = a['featured_on'].indexOf(locationType) !== -1;
      const bFeatured = b['featured_on'].indexOf(locationType) !== -1;

      // Put the featured_on[locationType] elements at the top
      if (locationType !== '') {
          if (aFeatured && !bFeatured) {
              return -1; // a to top
          } else if (!aFeatured && bFeatured) {
              return 1; // b to top
          }
      }

      if(this.sortOption === 'popularity'){
        //if a & b first_exp_published_timestamp are less than 15 days from now

        //if a is less than 15 days from now and not b
        if(a['first_exp_published_timestamp'] >= timestampFifteenDaysAgo && b['first_exp_published_timestamp'] < timestampFifteenDaysAgo){
          return -1; //a to top
        }

        //if b is less than 15 days from now and not a
        if(b['first_exp_published_timestamp'] >= timestampFifteenDaysAgo && a['first_exp_published_timestamp'] < timestampFifteenDaysAgo){
          return 1; //b to top
        }

        //put the newer one at the top
        if(a['first_exp_published_timestamp'] >= timestampFifteenDaysAgo && b['first_exp_published_timestamp'] >= timestampFifteenDaysAgo){
          return a['first_exp_published_timestamp'] >= b['first_exp_published_timestamp'] ? -1 : 1;
        }
      }

      //only used for appellation landings for now
      if(this.sortOption === 'relevance' && appellationId){
        if(a['appellations_ids'].indexOf(appellationId) === b['appellations_ids'].indexOf(appellationId)){
          return 0;
        }

        if(a['appellations_ids'].indexOf(appellationId) !== -1){
          return -1; //a to top
        }
        if(b['appellations_ids'].indexOf(appellationId) !== -1){
          return 1; //b to top
        }
      }

      if (order === 'DESC') {
          return b[propertyToSortOn] - a[propertyToSortOn];
      } else {
          return a[propertyToSortOn] - b[propertyToSortOn];
      }
    });
  }

  //if fallback request & if filters active > show the "no exact matches" + remove filters button
  buildNoExactMatchesBlock(){
    let removeFiltersButtonsBlock = '';

    if(this.mainFiltersActive)
      removeFiltersButtonsBlock = this.buildRemoveFiltersButtons();

    return `
      <div class="w-full md:px-2 text-center xl:text-left" id="no-results-block">
        <p class="font-semibold mt-4 text-lg">${this.searchWrapper.dataset.noExactMatchesText}</p>
        <p class="text-sm">${this.searchWrapper.dataset.tryChangingText}</p>
        <div class="flex flex-wrap items-center justify-center xl:justify-start mt-4">
          <button class="btn btn-outline-deep-green shadow-none text-black py-3 px-6 mr-3 sm:mr-4 mb-3 sm:mb-4" id="remove-all-filters">
            ${this.searchWrapper.dataset.removeAllFiltersText}
          </button>
          ${removeFiltersButtonsBlock}
        </div>
        <hr class="w-full my-6">
        <p class="font-semibold mb-5 text-lg">${this.searchWrapper.dataset.hostsNoExactMatchText}</p>
      </div>
    `;
  }

  //block with buttons like "remove price filter", "remove persons filter", ...
  buildRemoveFiltersButtons(){
    let html = '';
    this.mainActiveFiltersList.forEach(type => {
      let text = this.searchWrapper.getAttribute('data-remove-' + type);
      html += `<button class="btn btn-outline-deep-green shadow-none text-black py-3 px-6 mr-3 sm:mr-4 mb-3 sm:mb-4 remove-filter-button"
                        data-type="${type}">${text}</button>`;
    });

    return html;
  }

  //bind click on remove buttons
  bindRemoveFiltersButtons(){
    const noResultsBlock = document.getElementById('no-results-block');
    if(noResultsBlock){
      //bind specific filter all buttons
      const removeFilterButtons = noResultsBlock.querySelectorAll('.remove-filter-button');
      if(removeFilterButtons){
        removeFilterButtons.forEach(button => {
          button.addEventListener('click', (e) => {
            const type = e.target.dataset.type;
            if(type === 'price'){
              this.clearPrice();
            }
            else if(type === 'typeofexp'){
              this.clearTypeOfExp();
            }
            else if(type === 'days'){
              this.clearDate();
            }
            else if(type === 'persons'){
              this.clearPersons();
            }
            else if(type === 'langs'){
              this.clearLangs();
            }

            //compute new active filters count
            this.computeActiveFiltersCount();

            //remove the button
            e.target.remove();

            //trigger another search
            this.debouncedPerformSearch();
          });
        });
      }

      //bind remove all filters button
      const removeAllFiltersButton = noResultsBlock.querySelector('#remove-all-filters');
      if(removeAllFiltersButton){
        removeAllFiltersButton.addEventListener('click', (e) => {
          //clear all main filters
          this.clearPrice();
          this.clearTypeOfExp();
          this.clearDate();
          this.clearPersons();
          this.clearLangs();

          //and clear all secondary filters
          this.clearSecondaryFilters();

          //compute new active filters count
          this.computeActiveFiltersCount();

          //remove the button
          e.target.remove();

          //trigger another search
          this.debouncedPerformSearch();
        });
      }
    }
  }

  //if fallback request & no filters active > show the "No hosts in that area yet..." block
  buildNoHostsInThatAreaBlock(){
    return `
      <div class="w-full md:px-2 text-center xl:text-left">
        <p class="font-semibold mt-4 text-lg">${this.searchWrapper.dataset.noHostsAreaText}</p>
        <p class="text-sm">${this.searchWrapper.dataset.currentlyNoHostsText}</p>
        <hr class="w-full my-6">
        <p class="font-semibold mb-5 text-lg">${this.searchWrapper.dataset.otherHostsText}</p>
      </div>
    `;
  }

  updateHostsList(isFallbackRequest = false){
    let html = "";

    //if fallback request
    if(isFallbackRequest && this.hosts.length > 0){
      //if main filters active > show the "no exact matches" + remove filters button
      if(this.mainFiltersActive || this.secondaryFiltersActive){
        html += this.buildNoExactMatchesBlock();
      }
      //else show the "No hosts in that area yet..." block
      else{
        html += this.buildNoHostsInThatAreaBlock();
      }
    }

    if(this.hosts.length > 0){
      html += `<div class="flex flex-wrap w-full">`;

      //build the hosts cards through the <template> tag
      const template = document.getElementById('host-card');
      const templateSwiperSlide = document.getElementById('host-card-slide');
      const templateAppellations = document.getElementById('host-card-appellation');

      if(template && templateSwiperSlide && templateAppellations){
        //first, sort this.hosts to get the featured hosts first
        // this.hosts.sort(dynamicSort('featured_on', 'DESC', this.searchWrapper.dataset.locationType));

        //then build the cards
        this.hosts.forEach((host) => {
          let tempDiv = document.createElement('div');
          let isHostFeatured = typeof host.featured_on !== 'undefined' ? host.featured_on.indexOf(this.searchWrapper.dataset.locationType) !== -1
                                                                       : false;
          if(isHostFeatured){
            tempDiv.className = "xs:px-2 sm:px-2 w-full mb-10 xs:mb-4 sm:mb-4";
          }
          else{
            tempDiv.className = "xs:px-2 sm:px-2 w-full xs:w-1/2 sm:w-1/2 md:w-1/3 xl:w-1/2 mb-10";
          }

          tempDiv.appendChild(this.buildHostCardForList(template, templateSwiperSlide, templateAppellations, host, isHostFeatured));
          html += tempDiv.outerHTML;
        });
      }

      html += '</div>';
    }

    this.hostsContainer.innerHTML = html;

    //init the mouser hover on cards
    this.initCardMouseHover();

    //if needed, bind the remove filter buttons
    this.bindRemoveFiltersButtons();

    // bind the wishlist buttons
    Wish.bindWishButtons();

    //init any swiper slides
    initSwiper(this.hostsContainer);

    Drawer.bindOpenButtons(this.hostsContainer);

    //initOffuscation
    let hostContainer = document.querySelector('#hosts-container');
    initOffuscation(hostContainer);
  }

  buildHostCardForList(template, templateSwiperSlide, templateAppellations, host, hostFeatured = false){
    let hostCard = template.content.cloneNode(true);
    let wrapper = hostCard.querySelector('.host-card__wrapper');
    wrapper?.setAttribute('data-host-id', host.id);

    if(hostFeatured){
      wrapper?.classList.add('featured');
      let featuredTag = hostCard.querySelector('.host-card__featured');
      featuredTag?.classList.remove('hidden');
    }

    if(host.can_book_auto){
      let canBookAuto = hostCard.querySelector('.host-card__can_book_auto');
      canBookAuto?.classList.remove('hidden');
    }

    if(typeof host.culture_type !== 'undefined' && host.culture_type !== null){
      let cultureType = hostCard.querySelector('.host-card__culture_type');
      let translation = this.hostsContainer.getAttribute(this.cultureTypes[host.culture_type - 1]);
      if(cultureType){
        cultureType.textContent = translation;
        cultureType.classList.remove('hidden');
      }
    }

    //build the swiper slider
    let swiperWrapper = hostCard.querySelector('.host-card__swiper .swiper-wrapper');
    if(swiperWrapper){
      this.buildSwiperSlidesForHostCard(swiperWrapper, templateSwiperSlide, host);
    }

    let wishButton = hostCard.querySelector('.host-card__wish_button');
    wishButton?.setAttribute('data-model-id', host.id);

    //check if user has wishlisted this host
    let wishes = JSON.parse(document.body.dataset.wishes);
    let hostsInWishlist = wishes?.hostsId?.length > 0
                          ? wishes.hostsId
                          : [];
    let inWishlist = hostsInWishlist.includes(host.id);
    let wishIcon = wishButton.querySelector('.icon');
    wishIcon?.classList.toggle('icon-heart-filled', inWishlist);
    wishIcon?.classList.toggle('text-me-red', inWishlist);
    wishIcon?.classList.toggle('icon-heart', !inWishlist);

    let city = hostCard.querySelector('.host-card__city');
    if(city && host.experiences[0].city != '') city.textContent = capitalize(host.experiences[0].city);

    if(host.average_review){
      let reviewsWrapper = hostCard.querySelector('.host-card__reviews_wrapper');
      reviewsWrapper?.classList.remove('hidden');

      let reviewsCount = hostCard.querySelector('.host-card__reviews_count');
      if(reviewsCount) reviewsCount.textContent = reviewsCount.dataset.reviewsText.replace('{0}', host.count_review);

      let reviewsAverage = hostCard.querySelector('.host-card__reviews_average');
      if(reviewsAverage) reviewsAverage.textContent = host.average_review;

      let reviewsStars = hostCard.querySelector('.front-stars');
      if(reviewsStars) reviewsStars.style.width = `${host.average_review * 20}%`;
    }
    else{
      let noReviewsWrapper = hostCard.querySelector('.host-card__no_reviews_wrapper');
      noReviewsWrapper?.classList.remove('hidden');
    }

    let name = hostCard.querySelector('.host-card__name');
    name?.parentElement.setAttribute('href', host.url);
    name?.parentElement.setAttribute('title', host.name);
    if(name) name.textContent = host.name;

    let content = hostCard.querySelector('.host-card__content');
    if(content) content.textContent = host.content_algolia.slice(0, 100) + "...";

    //build the appellations list (if host has some)
    if(typeof host.appellations !== 'undefined'){
      let appellationsWrapper = hostCard.querySelector('.host-card__appellations');
      if(appellationsWrapper){
        this.buildAppellationsForHostCard(appellationsWrapper, templateAppellations, host);
        appellationsWrapper.classList.remove('hidden');
      }
    }

    let price = hostCard.querySelector('.host-card__price');
    if(price) price.textContent = this.getFormattedPrice(host);

    let locateButton = hostCard.querySelector('.host-card__locate-button');
    if(locateButton) locateButton.setAttribute('data-host-id', host.id);

    let offerButton = hostCard.querySelector('.host-card__offer');
    if(offerButton && this.wrapper.dataset.fromgift === 'true'){
      let link = offerButton.firstElementChild;
      if(link){
        link.dataset.hostId = host.id;
      }
      // link.dataset.redirectGo = window.btoa(link.dataset.redirectGo.replace('replaceHostId', host.id));
      offerButton.classList.remove('hidden');
    }

    return hostCard;
  }

  buildSwiperSlidesForHostCard(swiperWrapper, templateSwiperSlide, host){
    //build the slide for the host cover
    let slide = this.buildSwiperSlide(templateSwiperSlide, btoa(host.url), host.name, host.media_cdn_url, host.media_cdn_path);
    swiperWrapper.appendChild(slide);

    let picturesCount = 1;
    let maxPictures = 7;

    //then for the experiences covers
    host.experiences.forEach(experience => {
      let slide = this.buildSwiperSlide(
        templateSwiperSlide,
        btoa(host.url),
        experience.name,
        experience.coverCdnUrl,
        experience.coverCdnPath
      );

      swiperWrapper.appendChild(slide);
      picturesCount++;
    });

    //then fill with some of the experiences pictures to reach 10 slides/pictures
    if(picturesCount < maxPictures && host.hasOwnProperty('pictures') && host.pictures.length > 0){
      let picturesCountToAdd = maxPictures - picturesCount;
      if(picturesCountToAdd > 0){
        //limit to 8 max
        let pictures = host.pictures.slice(0, picturesCountToAdd);

        pictures.forEach(picture => {
          let slide = this.buildSwiperSlide(
            templateSwiperSlide,
            btoa(host.url),
            host.name,
            picture.cdnUrl,
            picture.cdnPath
          );

          swiperWrapper.appendChild(slide);
        });
      }
    }
  }

  buildSwiperSlide(templateSwiperSlide, url, alt, src, dataSrc){
    let slide = templateSwiperSlide.content.cloneNode(true);

    let picture = slide.querySelector('picture');
    picture?.setAttribute('data-redirect-go', url);
    picture?.setAttribute('target', '_blank');

    let img = slide.querySelector('img');
    img?.setAttribute('alt', alt);
    img?.setAttribute('src', src);
    // img?.setAttribute('src', 'https://picsum.photos/seed/' + Math.random(0, 10) + '/600/600'); //TODO MELCHIOR > remove
    img?.setAttribute('data-src', dataSrc);

    return slide;
  }

  buildAppellationsForHostCard(appellationsWrapper, templateAppellations, host){
    //slice the list of appellations to 2 max
    host.appellations.slice(0, 3).forEach(appellation => {
      let appellationElt = templateAppellations.content.cloneNode(true);
      let link = appellationElt.querySelector('a');
      if(link){
        link.setAttribute('href', appellation.url);
        link.textContent = appellation.name;
      }

      appellationsWrapper.appendChild(appellationElt);
    });
  }

  getFormattedPrice(host){
    let priceFormat = '';

    if(host.lowest_price_in_euros > 0){
      if(host.currency_iso != this.currencyInfos.iso){
        var minAmount = Math.ceil(host.lowest_price_in_euros * this.currencyInfos.rate);
        var maxAmount = Math.ceil(host.highest_price_in_euros * this.currencyInfos.rate);
      }
      else{
        var minAmount = host.lowest_price_in_euros * this.currencyInfos.rate;
        var maxAmount = host.highest_price_in_euros * this.currencyInfos.rate;
      }

      let minPrice = new Intl.NumberFormat(this.currencyInfos.locale, {
        style: 'currency',
        currency: this.currencyInfos.iso
      }).format(minAmount);
      let maxPrice = new Intl.NumberFormat(this.currencyInfos.locale, {
        style: 'currency',
        currency: this.currencyInfos.iso
      }).format(maxAmount);

      //if min = max, display only one price
      if(minAmount == maxAmount){
        priceFormat = minPrice;
      }
      //else display min - max
      else{
        priceFormat = minPrice + ' - ' + maxPrice;
      }
    }
    else {
      priceFormat = this.hostsContainer.dataset.free;
    }

    return priceFormat;
  }

  updateMarkersOnMap(){
    const _this = this;

    //Clears every current markers on map
    Object.values(this.markersPlaced).forEach(function (marker) {
      marker.remove();
    });

    this.hosts.forEach((host) => {
      let marker = document.createElement('div');
      marker.className = 'marker single-marker';
      marker.dataset.hostId = host.id;
      if (_this.alreadyVisitedMarkers.indexOf(host.id) !== -1) {
        marker.className += " already-visited";
      }

      if (host.can_book_auto) { //if host has an exp that has booking auto mode, add the booking auto icon
        let icon = buildBookingAutoIcon();
        marker.appendChild(icon);
      }

      marker.addEventListener('click', function () {
        //setTimeout is used to make sure this is done after map click event (which removes highlighted markers)
        window.setTimeout(() => {
          marker.classList.toggle("highlighted");
        }, 100);

        marker.classList.add('already-visited');
        if (_this.alreadyVisitedMarkers.indexOf(host.id) === -1) {
          _this.alreadyVisitedMarkers.push(host.id);
        }
      });

      let popupOptions = {
        offset: 15,
        closeButton: false,
        // className: this.searchWrapper.classList.contains('fullscreen') ? 'on-mobile' : ''
      };
      let popup = new this.maplibregl.Popup(popupOptions).setHTML(this.buildPopupHTML(host));

      //when the popup opens, scroll to the host card on the list
      popup.on("open", () => {
        const popupContent = popup.getElement();

        //force active on the first experience mini gallery picture
        highlighExperienceGalleryItem(popupContent, null, true);

        //init any swiper slider
        initSwiper(popupContent, {
          callbacks: {
            slideChangeTransitionEnd: (swiper) => {
              //when a slide is changed, highlight the experience mini gallery picture
              highlighExperienceGalleryItem(popupContent, swiper);
            }
          }
        });

        const cardOnList = this.wrapper.querySelector(`.host-card-list[data-host-id='${host.id}']`);
        if(cardOnList){
          const alreadyHighlightedCard = this.hostsContainer.querySelector('.highlighted');
          if(alreadyHighlightedCard) alreadyHighlightedCard.classList.remove('highlighted');

          cardOnList.classList.add('highlighted');
        }
      });

      _this.markersPlaced[host.id] = new this.maplibregl.Marker({element: marker})
        .setLngLat([host.lng, host.lat])
        .setPopup(popup)
        .addTo(_this.map);
    });
  }

  /**
   * Builds the HTML for the popup.
   *
   * @param {type} host - the host object
   * @return {string} the HTML for the popup
   */
  buildPopupHTML(host){
    let html = '';
    html += this.buildExperiencesSliderPopup(host); //showed on desktop
    html += this.buildHostCardPopup(host); //showed on mobile

    return html;
  }

  /**
   * Builds the experiences slider popup (for desktop only).
   *
   * @param {type} host - The host parameter description.
   * @return {type} The return value description.
   */
  buildExperiencesSliderPopup(host){
    // let fakeSrc = 'https://picsum.photos/seed/' + Math.floor(Math.random() * 1000) + '/600/600';
    let html = `<article class="card card-host experiences-slider-desktop on-popup bg-transparent max-w-xs w-80 overflow-hidden text-left" data-host-id="${host.id}">
    <div class="swiper w-full h-full rounded-lg overflow-hidden"
          data-per-view="1"
          data-per-view-xs="1"
          data-per-view-sm="1"
          data-per-view-lg="1"
          data-per-view-xl="1"
          data-per-view-wide-xl="1"
          data-per-view-ultrawide-xl="1">
      <div class="swiper-wrapper h-full w-full">`;

    if(typeof host.experiences !== 'undefined' && host.experiences.length > 0){
      host.experiences.forEach(experience => {
        let price = new Intl.NumberFormat(this.currencyInfos.locale, {
          style: 'currency',
          currency: this.currencyInfos.iso
        }).format(experience.price * this.currencyInfos.rate);

        if(experience.use_group_pricing){
          price = this.hostsContainer.dataset.from.replace("{0}", price);
        }

        // let priceFromTrad = this.hostsContainer.dataset.from.replace("{0}", price);
        let byTrad = this.hostsContainer.dataset.by.replace("{0}", host.name);

        let src = experience.coverCdnUrl + '/resize=2p';

        html += `
        <div class="swiper-slide w-full h-full">
          <div class="flex flex-col rounded" data-experience-id="${experience.id}">
            <a href="${experience.url}" target="_blank" class="block h-64 w-full bg-transparent rounded-t overflow-hidden img-blur-container">
              <img src="${src}" data-src="${experience.coverCdnPath}" class="twic h-full w-full object-cover"/>
            </a>
            <div class="p-4 flex flex-col">
              <div class="flex flex-wrap items-center">
                <span class="badge badge-champagnelight text-gray-700 mr-2">
                  ${experience.activity}
                </span>`;

        if(experience.count_reviews > 0){
          html += `
                  <div class="star-rating mr-1" title="${experience.average_review}">
                    <div class="back-stars">
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <div class="front-stars" style="width: ${experience.average_review * 20}%">
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                        </div>
                    </div>
                  </div>
                  <span class="text-xs mr-3">(${experience.count_reviews})</span>`;
        }

        html += `
                <span class="text-xs">
                  <i class="icon icon-time"></i> ${experience.durationAlgolia}
                </span>
              </div>
              <a href="${experience.url}" target="_blank" class="mt-2 font-semibold text-lg text-black hover:no-underline">
                ${experience.name}
              </a>
              <a href="${host.url}" class="text-sm relative z-50 mt-1">${byTrad}</a>
              <p class="mt-3 font-semibold text-sm">${price}</p>
            </div>
          </div>
        </div>
      `;
      });
    }

    html += `
      </div>

      <button type="button"
              class="swiper-prev left-0 ml-2 vertically-centered p-4 sm:p-2 z-20 hidden lg:flex items-center justify-center shadow-lg absolute rounded-full border border-gray-500 bg-white">
          <i class="icon icon-caret-left text-xl"></i>
      </button>

      <button type="button"
              class="swiper-next right-0 mr-2 vertically-centered p-4 sm:p-2 z-20 hidden lg:flex items-center justify-center shadow-lg absolute rounded-full border border-gray-500 bg-white">
          <i class="icon icon-caret-right text-xl"></i>
      </button>
    </div>`;

    //if host has more than one experience, setup the gallery pictures
    if(typeof host.experiences !== 'undefined' && host.experiences.length > 1){
      html += `<div class="gallery flex ${host.experiences.length > 5 ? 'flex-wrap' : ''} bg-white">`;

      let width = "16.6%";
      if(host.experiences.length <= 6){
          width = 100 / host.experiences.length + "%";
      }

      host.experiences.forEach((experience, index) => {
        let src = experience.coverCdnUrl + '/resize=2p';

        html += `
          <button type="button" class="gallery-item p-2 outline-none border-t-2 ${index == 0 ? 'active' : ''} flex justify-center bg-white"
                  style="width:${width}" data-index="${index}">
              <img class="h-8 w-8 rounded-lg object-cover" alt="${experience.name}"
                    draggable="false" src="${src}/cover=70x70" />
          </button>
        `;
      });

      html += `</div>`;
    }

    html += `
    </article>`;

    return html;
  }

  /**
   * Builds the host card popup (for mobile only).
   *
   * @return {string} The HTML string of the host card popup.
   */
  buildHostCardPopup(host){
    if(host.currency_iso != this.currencyInfos.iso){
      var amount = Math.ceil(host.lowest_price_in_euros * this.currencyInfos.rate);
    }
    else {
      var amount = host.lowest_price_in_euros * this.currencyInfos.rate;
    }

    let price = new Intl.NumberFormat(this.currencyInfos.locale, { style: 'currency', currency: this.currencyInfos.iso }).format(amount);
    let priceFormat = this.hostsContainer.dataset.from.replace("{0}", " <span class='text-me-red md:text-black md:font-semibold'>" + price + "</span> ");

    let html = `
    <article class="card card-host card-host-mobile on-popup bg-transparent overflow-hidden text-left w-full max-w-none flex h-40"
        data-host-id="${host.id}">
      <header class="relative rounded overflow-hidden w-full flex-shrink-0 mb-0">
          <picture class="h-full w-full">
              <img class="twic h-full w-full"
                  src="${host.media_cdn_url}/resize=2p"
                  data-src="${host.media_cdn_path}"
                  data-src-transform="cover">
          </picture>

          <div class="absolute bottom-0 right-0 p-4 whitespace-normal card__logo">
              <img class="object-contain bg-white rounded-full w-8 h-8 border border-champagne twic"
                      src="${host.brand_cdn_url}/resize=2p"
                      alt="${host.brand_alt ?? host.name}"
                      data-src="${host.brand_cdn_path}" data-src-transform="contain">
          </div>
      </header>
      <div class="p-4 pt-2 content flex flex-col justify-center">
      <a href="${host.url}"
          target="_blank"
          class="host-click-from-map host-name font-semibold mt-1 stretched-link text-black visible focus:outline-none max-w-full whitespace-no-wrap overflow-hidden text-lg"
          title="${host.name}">${host.name}</a>
      <p class="lg:font-normal price mt-2">${priceFormat}</p>`;

    if(host.average_review > 0 || host.can_book_auto){
      html += `<div class="flex flex-wrap items-center justify-start mt-2">`;
      if(host.average_review > 0){
        html += `<div class="rounded border uppercase text-xs px-2 py-1 mr-2 mt-1">
                      <i class="icon icon-star-alt text-yellow -mt-1"></i>
                      <span class="font-semibold">${host.average_review}</span>
                      <span class="text-gray-600"> (${host.count_review})</span>
                  </div>`;
      }
      if(host.can_book_auto){
        html += `<div class="rounded border uppercase text-xs px-2 py-1 mt-1 font-semibold">
                    <i class="icon icon-bolt text-me-red -mt-1"></i>
                    <span class="hidden xs:inline">${this.hostsContainer.dataset.bookingAuto}</span>
                </div>`;
      }

      html += `</div>`;
    }

    html += `</div>
    </article>`;

    return html;
  }

  //Toggle Map / Host List layout when clicking show map / show list
  initToggleMap(){
    this.toggleLayoutButton.addEventListener('click', () => {
      this.mapContainer.classList.toggle("hidden");
      this.hostsAndTitleContainer.classList.toggle("hidden");

      const span = this.toggleLayoutButton.querySelector("span");
      if (span) {
        let text = "";
        if (this.mapContainer.classList.contains("hidden")) {
          //Showing list of hosts
          text = this.toggleLayoutButton.dataset.showMap;
          this.searchWrapper.classList.remove('fullscreen');
          this.toggleLayoutWrapper.classList.remove('increase-z-index');

          //change icon
          if(this.iconToggleLayout){
            this.iconToggleLayout.classList.toggle('icon-list');
            this.iconToggleLayout.classList.toggle('icon-location');
          }

          let onMobile = window.matchMedia("(max-width: 1024px)").matches;
          if(onMobile){
            let btnSwapToHosts = document.querySelectorAll('.swap-to-hosts');
            if(btnSwapToHosts){
              btnSwapToHosts.forEach((button) => {
                button.classList.add('hidden');
              });
            }
          }
        }
        else {
          //Showing Map
          text = this.toggleLayoutButton.dataset.showList;

          //change icon
          if(this.iconToggleLayout){
            this.iconToggleLayout.classList.toggle('icon-location');
            this.iconToggleLayout.classList.toggle('icon-list');
          }

          window.setTimeout(() => {
            this.map.resize();
          }, 100);

          let onMobile = window.matchMedia("(max-width: 1024px)").matches;
          if(onMobile){
            this.searchWrapper.classList.add('fullscreen');
            this.toggleLayoutWrapper.classList.add('increase-z-index');

            let btnSwapToHosts = document.querySelectorAll('.swap-to-hosts');
            if(btnSwapToHosts){
              btnSwapToHosts.forEach((button) => {
                button.classList.remove('hidden');

                if (button.classList.contains('binded')) return;

                button.addEventListener('click', () => {
                  button.classList.add('binded');
                  button.classList.toggle('hidden');
                  this.toggleLayoutButton.click();
                });
              });
            }
          }
        }
        span.textContent = text;
      }
    });
  }

  /**
   * Initializes the "Around Me" feature.
   *
   * This function adds a click event listener to the "Around Me" button, which triggers the retrieval of the user's current geolocation.
   * The latitude and longitude coordinates are then used to center the map and set the zoom level.
   * If the marker for the user's current location has not been placed on the map yet, a new marker is created and added to the map at the specified coordinates.
   * The map is then updated to reflect the new center and zoom level.
   * Finally, the debouncedPerformSearch function is called to perform a search based on the updated map location.
   *
   * @param {type} paramName - description of parameter
   * @return {type} description of return value
   */
  initAroundMe(){
    this.triggerAroundMeButton.addEventListener('click', () => {
      this.performSearchOnAroundMe();
    });
  }

  performSearchOnAroundMe(){
    navigator.geolocation.getCurrentPosition((myPosition) => {
      let lat = myPosition.coords.latitude;
      let lng = myPosition.coords.longitude;

      let options = {
          center: [lng, lat],
          zoom: 11,
          speed: 2.5
      };

      this.mapContainer.dataset.lat = lat;
      this.mapContainer.dataset.lng = lng;
      this.setDistanceProperty();

      if(!this.aroundMeMarkerPlaced){
        let div = document.createElement('div');
        div.className = 'search-result-marker';
        new this.maplibregl.Marker({
          element: div
        })
        .setLngLat([lng, lat])
        .addTo(this.map);

        this.aroundMeMarkerPlaced = true;
      }

      this.map.jumpTo(options);
      this.debouncedPerformSearch();
    });
  }

  initCenterPointOnKiosk(){
    let div = document.createElement('div');
    div.className = 'search-result-marker';

    new this.maplibregl.Marker({
      element: div
    })
    .setLngLat([this.mapContainer.dataset.lng, this.mapContainer.dataset.lat])
    .addTo(this.map);
  }

  initCloseMoreFiltersButtons(){
    this.closeFiltersButtons.forEach(button => {
      button.addEventListener('click', () => {
        this.removeOverlay();
        this.removeMoreFilters();
      });
    });
  }

  /**
   * Initializes the sorting functionality.
   *
   * When an option is checked, it changes the button text to match the chosen option,
   * updates the sort option, closes the dropdown if applicable, sorts the hosts,
   * updates the host cards list, and scrolls the container to the top of the list.
   */
  initSorting(){
    this.sortOptions.forEach(option => {
      option.addEventListener('change', () => {
        if(option.checked){
          //change the button text to match the option chosen
          this.sortButtonText.textContent = option.dataset.text;
          this.sortOption = option.value;

          //close the dropdown
          const dropdown = option.closest('.dropdown');
          if(dropdown) dropdown.classList.remove('active');

          //sort hosts
          this.sortHosts();

          //then reorder host cards in the list
          if(this.hosts.length !== 0){
            this.updateHostsList();

            smoothScrollTo(this.hostsContainer, 0); //make sure we scroll full top of the hosts card list
          }
        }
      });
    });
  }

  initCardMouseHover(){
    const listCards = this.hostsContainer.querySelectorAll('.host-card-list:not(.hover-binded)');
    if(listCards){
      listCards.forEach(card => {
        card.addEventListener('mouseenter', () => {
          //remove the highlighted on a previous card (if there is one)
          const alreadyHighlightedCard = this.hostsContainer.querySelector('.highlighted');
          if(alreadyHighlightedCard) alreadyHighlightedCard.classList.remove('highlighted');

          //highlighted the current card that is hovered
          card.classList.add('highlighted');

          //also highlight the marker on the map
          this.openPopup(card.dataset.hostId);
        });

        card.classList.add('hover-binded');
      });
    }
  }

  initLocateButtons(){
    window.addEventListener('click', (e) => {
      if(e.target.classList.contains('locate-host-on-map')){
        let hostId = e.target.dataset.hostId;
        let marker = this.markersPlaced[hostId];
        if (marker) {
          this.highlightMarker(marker);

          //close every popups first to make sure that only 1 popup is opened at a time
          this.closeEveryPopups();

          let popup = marker.getPopup();
          if (popup && !popup.isOpen()) {
            //if a users click on locate host button and the map is hidden
            //it means that the user is on mobile and that we need to toggle the map too
            let onMobile = window.getComputedStyle(this.mapContainer, null).display === 'none';
            if(onMobile){
              this.toggleLayoutButton.click();
            }

            window.setTimeout(() => {
              marker.togglePopup();

              marker._element.classList.add('already-visited');
              if (this.alreadyVisitedMarkers.indexOf(Number(marker._element.dataset.hostId)) === -1) {
                this.alreadyVisitedMarkers.push(Number(marker._element.dataset.hostId));
              }

              this.map.flyTo({
                center: marker.getLngLat(),
                offset: [0, -150]
              });
            }, onMobile ? 250 : 0);
          }
        }
      }
    });
  }

  //trigger the marker popup corresponding to the hovered host in the list
  openPopup(hostId){
    let marker = this.markersPlaced[hostId];
    // let popup;

    if (marker) {
      // popup = marker.getPopup();

      this.highlightMarker(marker);
    }

    //make sure to not scroll to the already hovered host card (might cause unwanted UX behavior)
    // this.playOpenAnimation = false;

    // Close every popups
    // this.closeEveryPopups();

    // if (marker && popup && !popup.isOpen()) {
      // marker.togglePopup();
      // this.map.flyTo({ center: marker.getLngLat() });
    // }
  }

  highlightMarker(marker){
    //remove the highlight class on a potential existing active marker
    const markerHighlighted = this.mapContainer.querySelector(".marker.highlighted");
    if (markerHighlighted) {
      markerHighlighted.classList.toggle("highlighted");
    }

    //highlight the marker corresponding to the host card hovered
    marker._element.classList.toggle("highlighted");
  }

  closeEveryPopups(){
    // Close every popups
    Object.values(this.markersPlaced).forEach((markerTemp) => {
      let markerPopup = markerTemp.getPopup();
      if (markerPopup && markerPopup.isOpen()) {
        markerTemp.togglePopup();
      }
    });
  }

  //trigger the compute of active filters in the more filters window when a filter changes
  initComputeActiveFilters(){
    const inputs = this.moreFiltersWindow.querySelectorAll('input:not(.ignore-compute-active-filters)');
    if(inputs){
      inputs.forEach(input => {
        input.addEventListener('change', () => {
          this.computeActiveFiltersCount();
        });
      });

      this.computeActiveFiltersCount();
    }
  }

  //compute active filters in the more filters window
  computeActiveFiltersCount(){
    let activeFiltersCount = this.moreFiltersWindow.querySelectorAll('input:not(.ignore-compute-active-filters):checked').length;
    if(this.currentDateFromSelected !== null) activeFiltersCount++;
    if(this.adults > 0 || this.children > 0) activeFiltersCount++;
    if(this.maxPrice != this.hostsContainer.dataset.highestPrice || this.minPrice != 0) activeFiltersCount++;

    if(activeFiltersCount > 0){
      this.moreFiltersButton.classList.add('active');
      if(this.moreFiltersCountActiveElement){
        this.moreFiltersCountActiveElement.innerHTML = '&nbsp• ' + activeFiltersCount;
        this.moreFiltersCountActiveElement.classList.remove('hidden');
      }
    }
    else{
      if(this.moreFiltersCountActiveElement){
        this.moreFiltersCountActiveElement.classList.add('hidden');
      }

      this.moreFiltersButton.classList.remove('active');
    }
  }

  /**
   * Display the count of search results.
   *
   * @return {undefined} This function does not return a value.
   */
  // displayCountSearchResults(isFallbackRequest){
  //   if(this.displayCountSearchResultsContainer){
  //     //if its the fallback request, just hide the results count
  //     if(isFallbackRequest){
  //       this.displayCountSearchResultsContainer.classList.add('hidden');
  //       return;
  //     }

  //     let length = Object.keys(this.hosts).length;

  //     if(length > 0){
  //       let text = length == 1 ? this.displayCountSearchResultsContainer.dataset.textSingle : this.displayCountSearchResultsContainer.dataset.textPlural;
  //       this.displayCountSearchResultsContainer.innerHTML = text.replace('{0}', `<span class="font-semibold">${length}</span>`);
  //       this.displayCountSearchResultsContainer.classList.remove('hidden');
  //     }
  //     else{
  //       this.displayCountSearchResultsContainer.classList.add('hidden');
  //     }
  //   }
  // }

  //we check if every experience is actually available (has at least one timeslot opened at the specified date)
  // - called when a search is triggered with a date filter
  checkAvailability(experiences){
    let experience_ids = [];
    experiences.forEach(function(experience){
      experience_ids.push(experience.id);
    });

    let currentDateFromSelectedFormatted = dateToString(this.currentDateFromSelected);

    let formData = new FormData();
    formData.append('experience_ids', JSON.stringify(experience_ids));
    formData.append('date_from', currentDateFromSelectedFormatted);

    if(this.currentDateToSelected !== null){
      let currentDateToSelectedFormatted = dateToString(this.currentDateToSelected);
      formData.append('date_to', currentDateToSelectedFormatted);
    }

    formData.append('spots', this.spots);

    if(this.langs.length > 0){
      formData.append('langs_ids', JSON.stringify(this.langs));
    }

    const morning = this.wrapper.querySelector("input[data-algolia-attribute='morning']");
    const afternoon = this.wrapper.querySelector("input[data-algolia-attribute='afternoon']");
    if(morning && afternoon){
      formData.append('morning', morning.checked);
      formData.append('afternoon', afternoon.checked);
    }

    ajax(this.hostsContainer.dataset.filterByDate, function(data){
      let i = experiences.length;
      while (i--) {
          if (data.experiences_available.indexOf(experiences[i].id) === -1) {
            experiences.splice(i, 1);
          }
      }
    }, 'POST', formData, {
      "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
    }, false, true);
  }

  //set the distance from the initial center of the map to each host for the distance sorting
  setDistanceProperty(){
    //from the current center of the map ?
    // const {lng, lat} = this.map.getCenter();

    //or from the initial center of the map ?
    const lng = this.mapContainer.dataset.lng;
    const lat = this.mapContainer.dataset.lat;

    this.hosts.forEach((host) => {
      host['distance'] = getDistance(host.lat, host.lng, lat, lng);
    });
  }

  /**
   * Updates the session with the new filters.
   */
  updateSession(){
    const data = new FormData();
    data.append('adults', this.adults);
    data.append('children', this.children);
    data.append('from', this.globalFilterDate instanceof Date ? dateToString(this.globalFilterDate) : '');
    data.append('langs', JSON.stringify(this.langs));

    ajax(this.searchWrapper.dataset.sessionUrl, function () { }, 'POST', data, {
      "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
    });
  }
}

const capitalize = (string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export default MapHostSearch;
