import Glide, { Controls, Breakpoints, Swipe, Autoplay} from '@glidejs/glide/dist/glide.modular.esm'
import ArrowDisabler from "../frontv2/ArrowDisabler";

const initGlide = () => {
  const glides = document.querySelectorAll(".glide:not(.lightbox)");

  if (glides.length > 0) {
    glides.forEach(function (glideEl) {
      const glide = new Glide(glideEl, {
        perView: glideEl.dataset.perView ? glideEl.dataset.perView : 3,
        gap: glideEl.dataset.gap ? glideEl.dataset.gap : 10,
        type: 'slider',
        // rewind: !!glideEl.dataset.rewind,
        rewind: !!glideEl.dataset.rewind,
        bound: true,
        autoplay: glideEl.dataset.autoplay ? glideEl.dataset.autoplay : false,
        // hoverpause: !!glideEl.dataset.autoplay,
        breakpoints: {
          450: {
            perView: glideEl.dataset.mobilePerView ? glideEl.dataset.mobilePerView : 1.3
          },
          800: {
            perView: glideEl.dataset.tabletPerView ? glideEl.dataset.tabletPerView : 2.3
          },
          1000: {
            perView: glideEl.dataset.desktopPerView ? glideEl.dataset.desktopPerView : 3.3
          },
          1200: {
            perView: glideEl.dataset.largeDesktopPerView ? glideEl.dataset.largeDesktopPerView : glideEl.dataset.perView ? glideEl.dataset.perView : 3
          }
        }
      });

      if(glideEl.id == 'giftReviewsGlide'){
        glide.on('run.after', () => {
          const activeSlide = glideEl.querySelector('.glide__slide--active');

          if(activeSlide && activeSlide.dataset.id > 0){
            const activeReviewPhoto = document.querySelector('[data-review-id="'+activeSlide.dataset.id+'"]');

            activeReviewPhoto.classList.remove('hidden');
            activeReviewPhoto.classList.add('active');
            activeReviewPhoto.animate([
                // keyframes
                { opacity: .70 },
                { opacity: 1 }
              ], {
                // timing options
                duration: 400
              }
            );


            const reviewPhotos = document.querySelectorAll('[data-review-id]:not(.active)');
            reviewPhotos.forEach(photo => {
              photo.classList.add('hidden');
            });
          }
        });

        glide.on('run.before', () => {
          const activeSlide = glideEl.querySelector('.glide__slide--active');

          if(activeSlide && activeSlide.dataset.id > 0){
            const activeReviewPhoto = document.querySelector('[data-review-id="'+activeSlide.dataset.id+'"]');

            activeReviewPhoto.classList.remove('active');
            activeReviewPhoto.animate([
                // keyframes
                { opacity: 1 },
                { opacity: 0 }
              ], {
                // timing options
                duration: 1000
              }
            );
          }
        });
      }

      glide.mount({ Controls, Breakpoints, Swipe, ArrowDisabler, Autoplay });

      const galleryImages = document.querySelectorAll(glideEl.dataset.gallery + ' .photoMini');
      if(galleryImages){
        galleryImages.forEach(image => {
          let glideIndex = image.dataset.glideDir;
          image.addEventListener('click', () => {
            glide.go("=" + glideIndex);
          });
        });
      }
    });
  }
}

export { initGlide }
