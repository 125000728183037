import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";

class SearchBar {
  constructor(wrapper) {
    wrapper = typeof wrapper == 'undefined' ? 'search-bar' : wrapper;
    this.wrapper = document.getElementById(wrapper);
    this.aroundMe = document.getElementById('exp-around-me');
    if(this.wrapper) {
      const _this = this;
      this.datepicker = this.wrapper.querySelector('input.hiddenFlatpickr');
      this.destination = this.wrapper.querySelector('input[name=local_search]');
      this.lat = this.wrapper.querySelector('input[name=lat]');
      this.lng = this.wrapper.querySelector('input[name=lng]');
      this.zoom = this.wrapper.querySelector('input[name=zoom]');
      this.typeOfExperienceText = this.wrapper.querySelector(".typeOfExperienceText");
      this.typesOfExperience = this.wrapper.querySelectorAll('.typesOfExperience');
      this.clearTypesOfExperience = this.wrapper.querySelector('.clearActivities');
      this.saveTypesOfExperience = this.wrapper.querySelector('.saveActivities');
      this.personsText = this.wrapper.querySelector(".personsText span");
      this.personsInputs = this.wrapper.querySelectorAll(".personsInput");
      this.submit = this.wrapper.querySelector('form button[type=submit]');

      if(this.datepicker) {
        this.initDatePicker(this.datepicker);
      }

      if(this.aroundMe && this.destination){
        this.aroundMe.addEventListener("click", () => this.onAroundMeClick());
      }

      if(this.destination && this.submit){
        this.destination.addEventListener("keyup", () => this.onDestinationKeyUp());
        this.destination.dispatchEvent(new Event('keyup'));
      }

      if(this.typeOfExperienceText && this.typesOfExperience){
        this.typesOfExperience.forEach(function(typeOfExp){
          typeOfExp.addEventListener("click", () => _this.onTypeOfExpClick());
          typeOfExp.dispatchEvent(new Event('click'));
        });
      }

      if(this.clearTypesOfExperience && this.typeOfExperienceText){
        this.clearTypesOfExperience.addEventListener("click", function(){
          _this.typesOfExperience.forEach(function(typeOfExp){
            typeOfExp.checked = false;
          });
          _this.typeOfExperienceText.textContent = _this.typeOfExperienceText.dataset.initialText;
          _this.typeOfExperienceText.classList.remove("font-semibold");
        });
      }

      if(this.saveTypesOfExperience){
        this.saveTypesOfExperience.addEventListener('click', function(){
          _this.saveTypesOfExperience.closest('.dropdown').classList.remove('active');
        });
      }

      if(this.personsText && this.personsInputs){
        this.personsInputs.forEach(function(personsInput){
          personsInput.addEventListener("change", () => _this.onPersonsChange());
        });
      }

      this.autoEnableSubmit();
    }
  }

  initDatePicker(input) {
    const _this = this;
    const localeDateOptions = { month: 'short', day: 'numeric' };
    flatpickr.localize(flatpickrLanguages[document.body.dataset.lang.substr(0, 2)]);
    return flatpickr(input, {
      // altInput: true,
      // altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      inline: true,
      mode: 'single',
      minDate: new Date(),
      // disableMobile: true,
      allowInput: true,
      onChange: function(selectedDates, dateStr, instance) {
        if(selectedDates.length === 1) {
          let humanDate = _this.wrapper.querySelector('.human-date');
          if(humanDate){
            humanDate.innerHTML = selectedDates[0].toLocaleDateString(undefined, localeDateOptions);
            humanDate.classList.add("font-semibold");
            humanDate.closest(".dropdown").classList.remove("active");
          }
          // if(dateToString(selectedDates[0]) === dateToString(selectedDates[1])) {
          // } else {
          //   _this.wrapper.querySelector('.human-date').innerHTML = selectedDates[0].toLocaleDateString(undefined, localeDateOptions) + ' - ' + selectedDates[1].toLocaleDateString(undefined, localeDateOptions);
          // }
          //
          // if (selectedDates.length && selectedDates[0] && selectedDates[1]) {
          //   _this.wrapper.querySelector('#fromBar').value = dateToString(selectedDates[0]);
          //   _this.wrapper.querySelector('#toBar').value = dateToString(selectedDates[1]);
          // }
        }
        _this.autoEnableSubmit();
      },
    });
  }

  onAroundMeClick(){
    const _this = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (pos) {
        _this.destination.value = _this.aroundMe.dataset.aroundMeText;
        _this.lat.value = pos.coords.latitude;
        _this.lng.value = pos.coords.longitude;
        _this.zoom.value = 12;

        _this.autoEnableSubmit();
      });
      //ask geolocation permission
      // navigator.permissions.query({name:'geolocation'}); ?
    }
  }

  onDestinationKeyUp(){
    this.autoEnableSubmit();
  }

  onTypeOfExpClick(){
    const activeTypesOfExp = this.wrapper.querySelectorAll('.typesOfExperience:checked');
    let content = '';
    if(activeTypesOfExp){
      if(activeTypesOfExp.length > 1){
        for(let i = 0; i < activeTypesOfExp.length; i++) {
          content += activeTypesOfExp[i].dataset.name;
          if(i !== activeTypesOfExp.length - 1) {
            content += ', ';
          }
        }
        this.typeOfExperienceText.textContent = content;
        this.typeOfExperienceText.classList.add("font-semibold");
      }
      else if(activeTypesOfExp.length === 1){
        this.typeOfExperienceText.textContent = activeTypesOfExp[0].dataset.name;
        this.typeOfExperienceText.classList.add("font-semibold");
      }
      else{
        this.typeOfExperienceText.textContent = this.typeOfExperienceText.dataset.initialText;
        this.typeOfExperienceText.classList.remove("font-semibold");
      }
    }
    this.autoEnableSubmit();
  }

  onPersonsChange(){
    let numberOfPersons = 0;
    this.personsInputs.forEach(function(personsInput){
      numberOfPersons += parseInt(personsInput.value);
    });

    if(numberOfPersons === 0){
      this.personsText.innerText = this.personsText.dataset.initialText;
      this.personsText.classList.remove("font-semibold");
    }
    else if(numberOfPersons === 1){
      this.personsText.innerText = this.personsText.dataset.singularText;
      this.personsText.classList.add("font-semibold");
    }
    else{
      this.personsText.innerText = this.personsText.dataset.pluralText.replace("{0}", numberOfPersons);
      this.personsText.classList.add("font-semibold");
    }
    this.autoEnableSubmit();
  }

  autoEnableSubmit() {
    let numberOfPersons = 0;
    this.personsInputs.forEach(function(personsInput){
      numberOfPersons += parseInt(personsInput.value);
    });
    const activeTypesOfExp = this.wrapper.querySelectorAll('.typesOfExperience:checked');
    this.submit.disabled = !(this.destination.value !== '' || numberOfPersons > 0 || activeTypesOfExp.length > 0 || this.datepicker.value !== '');
  }
}

export default SearchBar;
