// ? How to use: import { initMelchiorSlider } from "./modules/common/init-melchior-slider";

// Init Melchior Slider
const initMelchiorSlider = () => {
    const rangeSliderContainer = document.querySelectorAll(".range_container:not(.binded)");
    if (!rangeSliderContainer) return;

    rangeSliderContainer.forEach((rangeContainer) => {
        const fromSlider = rangeContainer.querySelector(".fromSlider");
        const toSlider = rangeContainer.querySelector(".toSlider");
        const fromInput = rangeContainer.querySelector(".fromInput");
        const toInput = rangeContainer.querySelector(".toInput");

        if (!fromSlider || !toSlider || !fromInput || !toInput) return;

        fillColorSlider(fromSlider, toSlider, toSlider);
        setToggleAccessible(toSlider);

        fromSlider.oninput = () => controlFromSlider(fromSlider, toSlider, fromInput, rangeContainer);
        toSlider.oninput = () => controlToSlider(fromSlider, toSlider, toInput, rangeContainer);

        fromInput.addEventListener("input", debounce(() => {
            if(fromInput.value == "") return;
            controlFromInput(fromSlider, fromInput, toInput, toSlider, rangeContainer);
        }, 500));
        fromInput.addEventListener("focusout", () => {
            if(fromInput.value == "") {
                fromInput.value = fromSlider.min;
                controlFromInput(fromSlider, fromInput, toInput, toSlider, rangeContainer);
            }
        });

        toInput.addEventListener("input", debounce(() => {
            if(toInput.value == "") return;
            controlToInput(toSlider, fromInput, toInput, toSlider, rangeContainer);
        }, 500));
        toInput.addEventListener("focusout", () => {
            if(toInput.value == "") {
                toInput.value = toSlider.max;
                controlToInput(toSlider, fromInput, toInput, toSlider, rangeContainer);
            }
        });

        rangeContainer.addEventListener('reset', () => {
          fromSlider.value =  fromSlider.min;
          toSlider.value = toSlider.max;
          fromInput.value = fromInput.min;
          toInput.value = toInput.max;

          fillColorSlider(fromSlider, toSlider, toSlider);
        });

        rangeContainer.classList.add("binded");
    });
};

// Debounce function to limit the number of times a function is called.
const debounce = (func, delay) => {
    let timeout;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), delay);
    }
};

// Control the slider from the input
const controlFromInput = (fromSlider, fromInput, toInput, controlSlider, rangeContainer) => {
    const [from, to] = getParsed(fromInput, toInput);
    fillColorSlider(fromInput, toInput, controlSlider);
    if (from > to) {
        fromSlider.value = to;
        fromInput.value = to;
    } else {
        fromSlider.value = from;
    }

    dispatchValueChanged(rangeContainer, fromInput.value, toInput.value);
}

// Control the slider to the input
const controlToInput = (toSlider, fromInput, toInput, controlSlider, rangeContainer) => {
    const [from, to] = getParsed(fromInput, toInput);
    fillColorSlider(fromInput, toInput, controlSlider);
    setToggleAccessible(toInput, toSlider);
    if (from <= to) {
        if (to > toSlider.max) {
            toSlider.value = toSlider.max;
            toInput.value = toSlider.max;
        } else {
            toSlider.value = to;
            toInput.value = to;
        }
    } else {
        toInput.value = from;
        toSlider.value = from;
    }

    dispatchValueChanged(rangeContainer, fromInput.value, toInput.value);
}

// Control the input from the slider
const controlFromSlider = (fromSlider, toSlider, fromInput, rangeContainer) => {
    if(window.fromSliderValueChangedTimeout) clearTimeout(window.fromSliderValueChangedTimeout);

    const [from, to] = getParsed(fromSlider, toSlider);
    fillColorSlider(fromSlider, toSlider, toSlider);
    if (from > to) {
        fromSlider.value = to;
        fromInput.value = to;
    } else {
        fromInput.value = from;
    }

    window.fromSliderValueChangedTimeout = setTimeout(() => {
      dispatchValueChanged(rangeContainer, fromSlider.value, toSlider.value);
    }, 500);
}

// Control the input to the slider
const controlToSlider = (fromSlider, toSlider, toInput, rangeContainer) => {
    if(window.toSliderValueChangedTimeout) clearTimeout(window.toSliderValueChangedTimeout);

    const [from, to] = getParsed(fromSlider, toSlider);
    fillColorSlider(fromSlider, toSlider, toSlider);
    setToggleAccessible(toSlider);
    if (from <= to) {
        toSlider.value = to;
        toInput.value = to;
    } else {
        toInput.value = from;
        toSlider.value = from;
    }

    window.toSliderValueChangedTimeout = setTimeout(() => {
      dispatchValueChanged(rangeContainer, fromSlider.value, toSlider.value);
    }, 500);
}

const dispatchValueChanged = (rangeContainer, fromValue, toValue) => {
  rangeContainer.dispatchEvent(new CustomEvent('sliderValueChanged', {
    bubbles: false,
    detail: {
      fromValue: fromValue,
      toValue: toValue
    }
  }));
}

// Get the parsed value from the input
const getParsed = (currentFrom, currentTo) => {
    const from = parseInt(currentFrom.value, 10);
    const to = parseInt(currentTo.value, 10);
    return [from, to];
}

// Fill the slider with color (between the two sliders)
const fillColorSlider = (from, to, controlSlider) => {
    const rangeDistance = to.max - to.min;
    const fromPosition = from.value - to.min;
    const toPosition = to.value - to.min;
    const sliderColor = "#E6E6E6";
    const rangeColor = "#18171B";

    controlSlider.style.background = `linear-gradient(
        to right,
        ${sliderColor} 0%,
        ${sliderColor} ${(fromPosition / rangeDistance) * 100}%,
        ${rangeColor} ${(fromPosition / rangeDistance) * 100}%,
        ${rangeColor} ${(toPosition / rangeDistance) * 100}%,
        ${sliderColor} ${(toPosition / rangeDistance) * 100}%,
        ${sliderColor} 100%
    )`;
}

// Set the toggle accessible
const setToggleAccessible = (currentTarget, toSlider = null) => {
    if (!toSlider) toSlider = currentTarget;
    if (Number(currentTarget.value) <= Number(toSlider.min)) {
        toSlider.style.zIndex = 2;
    } else {
        toSlider.style.zIndex = 0;
    }
}

export { initMelchiorSlider };
