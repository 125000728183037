class MobileMenu {
  constructor() {
    this.wrapper = document.getElementById('mobile-fullscreen-nav');

    if (this.wrapper) {
      const _this = this;
      this.explore = document.getElementById('explore');
      this.countries = this.wrapper.querySelectorAll('.countries');
      this.regions = this.wrapper.querySelectorAll('.regions');
      this.activities = this.wrapper.querySelectorAll('.activities');

      if(this.explore && this.countries){
        this.explore.addEventListener('click', () => this.onExploreClick());
      }

      if(this.countries){
        this.countries.forEach(function(country){
          country.addEventListener('click', () => _this.onCountryClick(country));
        });
      }

      if(this.regions){
        this.regions.forEach(function(region){
          region.addEventListener('click', () => _this.onRegionClick(region));
        });
      }

      this.unfoldElts = this.wrapper.querySelectorAll('.unfold-on-click');
      if(this.unfoldElts){
        this.initUnfoldItems();
      }
   }
  }

  onExploreClick(){
    const _this = this;
    let active = this.explore.classList.toggle('active');
    this.countries.forEach(function(country){
      const button = country.querySelector('button');
      const icon = button.querySelector('i');

      if(!active){
        button.classList.remove('active');
        country.classList.add('hidden');
      } else {
        country.classList.remove('hidden');
      }

      if(icon)
        _this.handleIconChange(icon, country.classList.contains('active'));
    });

    this.regions.forEach(function(region){
      const button = region.querySelector('button');
      const icon = button.querySelector('i');

      if(!active){
        button.classList.remove('active');
        region.classList.add('hidden');
      }

      if(icon)
        _this.handleIconChange(icon, region.classList.contains('active'));
    });

    this.activities.forEach(function(activity){
      const icon = activity.querySelector('i');

      if(!active){
        activity.classList.add('hidden');
      }

      if(icon)
        _this.handleIconChange(icon, activity.classList.contains('active'));
    });

    const icon = this.explore.querySelector('i');
    this.handleIconChange(icon, this.explore.classList.contains('active'));
  }

  onCountryClick(country){
    const _this = this;

    const button = country.querySelector('button');
    let active = button.classList.toggle('active');

    const countryId = country.dataset.countryId;
    const regions = this.wrapper.querySelectorAll("[data-country='" + countryId + "']");
    regions.forEach(function(region){
      region.classList.toggle('hidden');
      if(!active){
        const button = region.querySelector('button');
        button.classList.remove('active');
        const icon = button.querySelector('i');
        _this.handleIconChange(icon, button.classList.contains('active'));
      }
    });
    if(!active){
      this.activities.forEach(function(activity){
        activity.classList.add('hidden');
      });
    }

    const icon = button.querySelector('i');
    this.handleIconChange(icon, button.classList.contains('active'));
  }

  onRegionClick(region){
    const button = region.querySelector('button');
    button.classList.toggle('active');

    const regionId = region.dataset.regionId;
    const activities = this.wrapper.querySelectorAll("[data-region='" + regionId + "']");
    activities.forEach(function(activity){
      activity.classList.toggle('hidden');
    });

    const icon = button.querySelector('i');
    this.handleIconChange(icon, button.classList.contains('active'));
  }

  handleIconChange(icon, active){
    if(active){
      icon.classList.remove('icon-caret-right');
      icon.classList.add('icon-caret-down');
    } else {
      icon.classList.add('icon-caret-right');
      icon.classList.remove('icon-caret-down');
    }
  }

  initUnfoldItems(){
    this.unfoldElts.forEach(elt => {
      const items = elt.parentElement.querySelector('ul');
      const icon = elt.querySelector('i');

      elt.addEventListener('click', () => {
        items.classList.toggle('hidden');

        if(icon){
          icon.classList.toggle('icon-caret-right');
          icon.classList.toggle('icon-caret-down');
        }
      });
    })
  }
}

export default MobileMenu;
