import Glide, { Breakpoints, Controls, Swipe } from "@glidejs/glide/dist/glide.modular.esm";
import ArrowDisabler from "./ArrowDisabler";

//creates a div with the instant booking icon
const buildBookingAutoIcon = () => {
  let bookingAuto = document.createElement('div');
  bookingAuto.className = "booking-auto absolute bg-blue flex justify-center items-center w-5 h-5 rounded-full";

  let icon = document.createElement('i');
  icon.className = "icon icon-bolt text-white text-xs";

  bookingAuto.appendChild(icon);
  return bookingAuto;
}

//Removes popup from experiences when the map is clicked
const removeMarkerHighlight = () => {
  const markerHighlighted = document.querySelector(".marker.highlighted");
  if (markerHighlighted) {
    markerHighlighted.classList.toggle("highlighted");
  }
}

const buildPopupHTML = (mapContainer, currencyInfos, experiences, target, utm) => {
  let html = "";
  if (experiences.length > 1) { //if there are multiple experiences at the same spot (lat - lng), we build a carousel (with glide) with the experiences
    html = `
    <div class="glide arrows_in glide-popup opacity-0" >
        <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides">
    `;

    for (let i = 0; i < experiences.length; i++) {
      let experience = experiences[i];

      html += buildPopupBody(mapContainer, currencyInfos, experience, true, i, target, utm);
    }

    html += `
        </ul>
      </div>
      <div class="glide__arrows" data-glide-el="controls">
          <button class="glide__arrow glide__arrow--left text-black bg-white rounded-full border w-10 h-10 text-center" data-glide-dir="<" aria-label="Go back to previous pictures" title="Go back to previous pictures"><i class="icon icon-caret-left"></i></button>
          <button class="glide__arrow glide__arrow--right text-black bg-white rounded-full border w-10 h-10 text-center" data-glide-dir=">" aria-label="Go to next pictures" title="Go to next pictures"><i class="icon icon-caret-right"></i></button>
      </div>
    </div>
    <div class="gallery flex ${experiences.length > 6 ? 'flex-wrap' : ''}">
    `;

    let width = "16.6%";
    if(experiences.length <= 6){ //make sure there are only 6 experiences thumbnails maximum on the same row
      width = 100 / experiences.length + "%";
    }
    for (let i = 0; i < experiences.length; i++) {
      html += `
        <button class="galleryItem p-2 outline-none border-t-2 ${i === 0 ? 'active' : ''} flex justify-center" style="width:${width}" data-index="${i}">
            <img class="h-10 w-10 rounded-lg object-cover" alt="${experiences[i].name}" draggable="false" src="${experiences[i].cover_cdn_url}/cover=70x70"/>
        </button>
      `;
    }

    html += `
    </div>
    `;

  }
  else { //if there's only one experience at the spot
    let experience = experiences[0];
    html += buildPopupBody(mapContainer, currencyInfos, experience, false, 0, target, utm);
  }

  return html;
}

//build the popup body (with all experience infos)
const buildPopupBody = (mapContainer, currencyInfos, experience, isSlider, index, target, utm) => {
  let components = getExperienceComponents(mapContainer, currencyInfos, experience);

  let html = isSlider ? `<li class="glide__slide ${experience.objectID}" data-index=${index}>` : ``; //if it's a carousel (see above in buildPopupHTML)
  html += `
    <div class="flex flex-col rounded popup">
      <a href="${experience.url + utm}" target="${target}" class="block h-56 w-full bg-transparent rounded-t overflow-hidden">
        <img class="h-full w-full object-cover" alt="${experience.name}" draggable="false" src="${experience.cover_cdn_url}/cover=600x420"/>
      </a>
      <div class="p-4 relative">
        <div class="flex flex-wrap items-center mb-2">
          <span class="badge badge-champagnelight text-gray-700 mr-2">${experience.activityLevelOneName}</span>
          ${components.reviews}
          <span class="text-xs text-gray-500 ${experience.average_review ? 'ml-3' : ''}"><i class="icon icon-time"></i> ${experience.duration_algolia}</span>
        </div>
        <a href="${experience.url + utm}" target="${target}" class="mt-1 font-semibold text-base text-black hover:no-underline">${experience.name}</a>
        <p class="mt-2 text-sm">${components.priceFormat}</p>
        <a href="${experience.host.url + utm}" target="${target}" class="text-gray-700 text-xs relative z-50">${components.byTrad}</a>
        ${components.booking_auto}
      </div>
    </div>
  `;

  if (isSlider) {
    html += `</li>`;
  }

  return html;
}

//Build the html for the experiences cards in mobile
const buildMobilePopup = (mapContainer, currencyInfos, mobileCardsContainer, experiences, target, utm) => {
  let html = "";
  if (mobileCardsContainer) {
    if (experiences.length > 1) {
      experiences.forEach(function (experience) {
        let components = getExperienceComponents(mapContainer, currencyInfos, experience);
        html += buildMobilePopupExperience(experience, components, true, target, utm);
      });
    }
    else {
      let components = getExperienceComponents(mapContainer, currencyInfos, experiences[0]);
      html += buildMobilePopupExperience(experiences[0], components, false, target, utm);
    }
  }

  return html;
}

//get experience components (like formatted price, booking auto div, reviews div)
const getExperienceComponents = (mapContainer, currencyInfos, experience) => {
  let priceFormat;
  if(experience.host.currency_iso != currencyInfos.iso)
    var amount = Math.ceil(experience.price_in_euros * currencyInfos.rate);
  else
    var amount = experience.price_in_euros * currencyInfos.rate;

  var price = new Intl.NumberFormat(currencyInfos.locale, { style: 'currency', currency: currencyInfos.iso }).format(amount);
  if(experience.price > 0){
    priceFormat = mapContainer.dataset.from.replace("{0}", price);
  } else {
    priceFormat = mapContainer.dataset.free; //Watch this when refactiong search
  }
  let byTrad = mapContainer.dataset.by.replace("{0}", experience.host.name);
  // let onMobile = window.matchMedia("(max-width: 768px)").matches;

  let booking_auto = ``;
  if (experience.booking_auto) {
    booking_auto =
      `
      <div class="bg-blue absolute right-0 bottom-0 mr-2 mb-2 flex justify-center items-center w-5 h-5 rounded-full">
        <i class="icon icon-bolt text-white text-xs"></i>
      </div>
    `;
  }

  let reviews = ``;
  if (experience.nb_reviews > 0) {
    reviews = `
    <div class="rounded border uppercase text-xs px-2 mr-2 mt-1">
      <i class="icon icon-star-alt text-yellow -mt-1"></i>
      <span class="font-semibold">${experience.review}</span>
      <span class="text-muted"> (${experience.nb_reviews})</span>
    </div>
    `;
  }

  return {
    price: price,
    priceFormat: priceFormat,
    byTrad: byTrad,
    booking_auto: booking_auto,
    reviews: reviews
  };
}

//build the popup (that appears when a marker is clicked) - FOR MOBILE
const buildMobilePopupExperience = (experience, components, isCluster, target, utm) => {
  let onMobile = window.matchMedia("(max-width: 640px)").matches;

  let html = `
    <div class="mobilePopup w-11/12 ${isCluster ? "mr-2" : "mx-auto"} shadow-xl inline-flex rounded bg-white whitespace-normal h-full">
      <div class="w-5/12 bg-transparent p-3">
        <a href="${experience.url + utm}" target="${target}">
          <img class="h-full w-full object-cover rounded" alt="${experience.name}" draggable="false" src="${experience.cover_cdn_url}/cover=600x420"/>
        </a>
      </div>
      <div class="p-3 w-7/12 flex flex-col justify-center relative">
        <div class="flex flex-wrap items-center">
          <span class="badge badge-champagnelight text-gray-700 mr-2 mt-1">${experience.activityLevelOneName}</span>
          ${components.reviews}
          <span class="text-xs text-gray-500 ${experience.average_review ? 'ml-3' : ''} ${onMobile ? 'mt-1' : ''}">
            <i class="icon icon-time"></i>
            ${experience.duration_algolia}
          </span>
        </div>
        <a href="${experience.url + utm}" target="${target}" class="mt-1 font-semibold text-base text-black hover:no-underline overflow-hidden">${experience.name}</a>
        <p class="mt-2 text-sm pricing">${components.priceFormat}</p>
        <a href="${experience.host.url + utm}" target="${target}" class="text-gray-700 text-xs relative z-50">${components.byTrad}</a>
        ${components.booking_auto}
      </div>
    </div>
  `;

  return html;
}

const initializeGlider = () => {
  window.setTimeout(function(){
    const glideEl = document.querySelector(".glide-popup");
    if (glideEl) {
      let glide = new Glide(glideEl, {
        perView: 1,
        type: 'carousel',
        rewind: false,
        bound: true,
      });

      glide.on('build.after', () => {
        if (glideEl.classList.contains("opacity-0")) {
          glideEl.classList.toggle("opacity-0");
          glideEl.classList.toggle("opacity-100");
        }

        const galleryItems = document.querySelectorAll(".galleryItem");
        if(galleryItems){
          galleryItems.forEach(function(item){
            item.addEventListener("click", function(){
              glide.go("=" + item.dataset.index);
            });
          })
        }
      });

      glide.on("run.after", function () {
        let currentActiveExp = document.querySelector(".galleryItem.active");
        if (currentActiveExp) {
          currentActiveExp.classList.toggle("active");
        }

        let glideIndex = glide.index;
        let gallery = document.querySelectorAll(".gallery .galleryItem");
        if (gallery && gallery[glideIndex]) {
          gallery[glideIndex].classList.toggle("active");
        }
      });

      if (glide && !glideEl.classList.contains("glide--carousel")) {
        glide.mount({ Controls, Breakpoints, Swipe, ArrowDisabler });

        // currentGlide = glide;
      }
    }
  }, 200);
};

const mapTilesStyle = 'https://cdn.winalist.com/stadia/map_style_v2.json';

export { buildBookingAutoIcon, removeMarkerHighlight, buildPopupHTML, buildMobilePopup, initializeGlider, mapTilesStyle }
