const initCollapsible = () => {
  const collapsibles = document.querySelectorAll('.collapsible');
  collapsibles.forEach(collapsible => {
    const collapsibleToggle = collapsible.querySelector('.collapsible-toggle');
    if(collapsibleToggle){
      collapsibleToggle.addEventListener('click', function () {
        collapsible.classList.toggle('opened');
      });
    }
    else{
      collapsible.addEventListener('click', function () {
        collapsible.classList.toggle('opened');
      });
    }
  });
}

export { initCollapsible }
