const initGoogleEcommerce = () => {
  let dataLayer = window.dataLayer || [];
  const btnBook = document.querySelector("button[data-cy='book']");
  if(btnBook) {
    btnBook.addEventListener('click', function () {
      if (typeof dataLayer != 'undefined') {
        dataLayer.push({
          "event": "EEaddToCart",
          "ecommerce": {
            "currencyCode": "EUR",
            "add": {
              "products": [{
                'name': btnBook.dataset.productName,
                'id': btnBook.dataset.productId,
                'price': btnBook.dataset.productPrice,
                'brand': btnBook.dataset.productBrand,
                'category': 'Experience',
                'quantity': 1
              }]
            }
          }
        });
      }
    });
  }

  const cart = document.querySelector('input[type=hidden][name=cart]');

  if(cart) {
    let dataObject = {
      'event': 'checkout_view',
      'category': 'page',
      'label': 'label_checkout_view'
    };

    if (typeof dataLayer != 'undefined') {
      dataLayer.push(dataObject);
      dataLayer.push({
        'event': 'EEcheckout',
        'ecommerce': {
          'checkout': {
            'actionField': {'step': 1},
            'products': [{
              'name': cart.dataset.productName,
              'id': cart.dataset.productId,
              'price': cart.dataset.amount,
              'brand': cart.dataset.productBrand,
              'category': 'Experience',
              'quantity': 1
            }]
          }
        }
      });
    }
  }

  if(typeof dataLayer != 'undefined'){
    const getAnalyticsOptions = (wrapper, price) => {
      return {
        item_id: wrapper.dataset.experienceId,
        item_name: wrapper.dataset.experienceName,
        currency: wrapper.dataset.hostCurrency,
        item_brand: wrapper.dataset.hostName,
        item_category: wrapper.dataset.categoryOne,
        item_category2: wrapper.dataset.categoryTwo,
        item_category3: wrapper.dataset.categoryThree,
        item_category4: wrapper.dataset.categoryFour,
        quantity: 1
      };
    }

    const btnAddToCart = document.querySelectorAll('.btn-add-to-cart');
    const bookingSection = document.getElementById('booking-section');
    if(btnAddToCart && bookingSection){
      //add to cart events
      btnAddToCart.forEach(btn => {
        btn.addEventListener('click', () => {
          try{
            let suffix = btn.classList.contains('mobile') ? 'mobile' : 'desktop';
            const seatsAdult = document.getElementById('seats-adult-' + suffix);
            const seatsChild = document.getElementById('seats-child-' + suffix);

            let price = bookingSection.dataset.adultPrice * seatsAdult.value;
            if(seatsChild){
              price += bookingSection.dataset.childPrice * seatsChild.value;
            }

            let options = getAnalyticsOptions(bookingSection, price);
            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push({
              event: "add_to_cart",
              ecommerce: {
                currency: options.currency,
                value: price,
                items: [options]
              }
            });
          } catch (err){
            console.error(err);
          }
        });
      });

      //and also view item event
      try {
        let price = bookingSection.dataset.adultPrice;

        let options = getAnalyticsOptions(bookingSection, price);
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
          event: "view_item",
          ecommerce: {
            currency: options.currency,
            value: price,
            items: [options]
          }
        });
      } catch (err) {
        console.error(err);
      }
    }

    const giftFormCheckout = document.getElementById('gift-choose-form');
    const giftCheckoutWrapper = document.getElementById('gift-card-wrapper');
    if(giftFormCheckout && giftCheckoutWrapper){
      giftFormCheckout.addEventListener('submit', () => {
        try{
          let price = 0;
          const gcType = document.querySelector("input[name='type']:checked");
          const priceRadio = document.querySelector("input[name='radioAmount']:checked");
          const customPrice = document.querySelector("input[name='amount']");
          const expBasedAmount = document.getElementById('experience-based-amount');
          if(expBasedAmount && expBasedAmount.checked){
            const expBasedInputAdults = document.getElementById('experience-based-amount-input-adults');
            const expBasedInputChildren = document.getElementById('experience-based-amount-input-children');
            price = expBasedInputAdults.value * expBasedInputAdults.dataset.price;
            if(expBasedInputChildren && expBasedInputChildren.value != 0){
              price += expBasedInputChildren.value * expBasedInputChildren.dataset.price;
            }
          }
          else{
            price = priceRadio ? priceRadio.value : customPrice.value;
          }

          let options = {
            item_id: giftCheckoutWrapper.dataset.experienceId,
            item_name: gcType.value,
            currency: 'EUR',
            item_brand: giftCheckoutWrapper.dataset.hostName,
            item_category: giftCheckoutWrapper.dataset.categoryOne,
            item_category2: giftCheckoutWrapper.dataset.categoryTwo,
            item_category3: giftCheckoutWrapper.dataset.categoryThree,
            item_category4: giftCheckoutWrapper.dataset.categoryFour,
            quantity: 1
          }

          dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              currency: options.currency,
              value: price,
              items: [options]
            }
          });
        } catch (err){
          console.error(err);
        }
      });
    }
  }
};

export { initGoogleEcommerce };
